import { API_ASSIGN_BY_OPPORTUNITY, API_GET_BROKER_NAME, API_GET_OPPORTUNITY_QUESTION, API_MASTER_GET_INSURER, API_MASTER_GET_POLICY_TYPE, API_MASTER_PROBABILITY, API_OPPORTUNITY_QUESTION_LIST, API_PROPOSALPOOL_EDIT, API_PROPOSALPOOL_VIEW, API_DEPARTMENT_WISE_GET_INSURER } from '../../../../../api/constants';
import { API_OPPORTUNITY_STATUS } from '../../../../../api/constants';
import { API_OPPORTUNITY_QUOTE_REJECT_STATUS } from '../../../../../api/constants';
import { API_OPPORTUNITY_LOST_STATUS } from '../../../../../api/constants';
import { API_OPPORTUNITY_PLACEMENTS_GET } from '../../../../../api/constants';
import { API_INTERMEDIARY_TYPE } from '../../../../../api/constants';
import { API_CLOSURE_TYPE } from '../../../../../api/constants';
import { API_DELETE_OPPORTUNITY_DOCUMENT } from '../../../../../api/constants';
import { API_INSTALLMENT,API_GET_TAG_USER } from '../../../../../api/constants';
import Api from '../../../../../api';
import { fetchSuccess, loading } from '../../../../../utils/action.util';

export const ACTION_PROPOSALPOOL_EDIT_LOADING = 'ACTION_PROPOSALPOOL_EDIT_LOADING';
export const ACTION_PROPOSALPOOL_EDIT_SUCCESS = 'ACTION_PROPOSALPOOL_EDIT_SUCCESS';

export const ACTION_OPPORTUNITY_STATUS_LOADING = 'ACTION_OPPORTUNITY_STATUS_LOADING';
export const ACTION_OPPORTUNITY_STATUS_SUCCESS = 'ACTION_OPPORTUNITY_STATUS_SUCCESS';

export const ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_SUCCESS = "ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_SUCCESS";
export const ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_LOADING = "ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_LOADING";

export const ACTION_OPPORTUNITY_LOST_STATUS_SUCCESS = "ACTION_OPPORTUNITY_LOST_STATUS_SUCCESS";
export const ACTION_OPPORTUNITY_LOST_STATUS_LOADING = "ACTION_OPPORTUNITY_LOST_STATUS_LOADING";

export const ACTION_OPPORTUNITY_PLACEMENTS_GET_SUCCESS = "ACTION_OPPORTUNITY_PLACEMENTS_GET_SUCCESS";
export const ACTION_OPPORTUNITY_PLACEMENTS_GET_LOADING = "ACTION_OPPORTUNITY_PLACEMENTS_GET_LOADING";

export const ACTION_MASTER_POLICY_LOADING = 'ACTION_MASTER_POLICY_LOADING';
export const ACTION_MASTER_OPPORTUNITY_POLICY_SUCCESS = 'ACTION_MASTER_OPPORTUNITY_POLICY_SUCCESS';

export const ACTION_MASTER_INSURANCE_COMPANY_LOADING = 'ACTION_MASTER_INSURANCE_COMPANY_LOADING';
export const ACTION_MASTER_INSURANCE_OPPORTUNITY_SUCCESS = 'ACTION_MASTER_INSURANCE_OPPORTUNITY_SUCCESS';

export const ACTION_INTERMEDIARY_TYPE_SUCCESS = 'ACTION_INTERMEDIARY_TYPE_SUCCESS';
export const ACTION_INTERMEDIARY_TYPE_LOADING = "ACTION_INTERMEDIARY_TYPE_LOADING";

export const ACTION_OPPORTUNITY_BROKER_NAME_SUCCESS = "ACTION_OPPORTUNITY_BROKER_NAME_SUCCESS"
export const ACTION_OPPORTUNITY_BROKER_NAME_LOADING = "ACTION_OPPORTUNITY_BROKER_NAME_LOADING"

export const ACTION_MASTER_PROBABILITY_LOADING = 'ACTION_MASTER_PROBABILITY_LOADING';
export const ACTION_MASTER_PROBABILITY_SUCCESS = 'ACTION_MASTER_PROBABILITY_SUCCESS';

export const ACTION_OPPORTUNITY_QUESTIONS_LIST_LOADING = 'ACTION_OPPORTUNITY_QUESTIONS_LIST_LOADING';
export const ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS = 'ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS';

export const ACTION_GET_OPPORTUNITY_QUESTIONS_LOADING = 'ACTION_GET_OPPORTUNITY_QUESTIONS_LOADING';
export const ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS = 'ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS';

export const ACTION_GET_CLOSURE_TYPE_LOADING = 'ACTION_GET_CLOSURE_TYPE_LOADING';
export const ACTION_GET_CLOSURE_TYPE_SUCCESS = 'ACTION_GET_CLOSURE_TYPE_SUCCESS';

export const ACTION_PROPOSALPOOL_VIEW_LOADING = 'ACTION_PROPOSALPOOL_VIEW_LOADING';
export const ACTION_PROPOSALPOOL_VIEW_SUCCESS = 'ACTION_PROPOSALPOOL_VIEW_SUCCESS';

export const ACTION_GET_ASSIGN_PRODUCT_RM_LOADING = 'ACTION_GET_ASSIGN_PRODUCT_RM_LOADING';
export const ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS = 'ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS';

export const ACTION_DEPARTMENT_WISE_GET_INSURANCE_COMPANY_LOADING = 'ACTION_DEPARTMENT_WISE_GET_INSURANCE_COMPANY_LOADING';
export const ACTION_DEPARTMENT_WISE_GET_INSURANCE_OPPORTUNITY_SUCCESS = 'ACTION_DEPARTMENT_WISE_GET_INSURANCE_OPPORTUNITY_SUCCESS';

export const ACTION_DELETE_DOCUMENT_SUCCESS = 'ACTION_DELETE_DOCUMENT_SUCCESS';
export const ACTION_DELETE_DOCUMENT_LOADING = 'ACTION_DELETE_DOCUMENT_LOADING'

export const ACTION_INSTALLMENT_SUCCESS = 'ACTION_INSTALLMENT_SUCCESS';
export const ACTION_INSTALLMENT_LOADING = 'ACTION_INSTALLMENT_LOADING';

export const ACTION_INSURANCE_SUCCESS_LIST_SUCCESS = 'ACTION_INSURANCE_SUCCESS_LIST_SUCCESS';
export const ACTION_INSURANCE_SUCCESS_LIST_LOADING = 'ACTION_INSURANCE_SUCCESS_LIST_LOADING';


export const ACTION_GET_TAG_USER_LOADING = 'ACTION_GET_TAG_USER_LOADING';
export const ACTION_GET_TAG_USER_SUCCESS = 'ACTION_GET_TAG_USER_SUCCESS';


export function actionTagUserList(params) {
    return (dispatch, getState) =>
        Api.get(API_GET_TAG_USER, params).then((res) => {
            const data = res;
            // PRINT('ACTION SELECT CUSTOMER RESPONSE', data)
            dispatch(fetchSuccess(ACTION_GET_TAG_USER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_TAG_USER_LOADING, { loading: false, data }));
        });
}

export function actionProposalPoolEdit(params, files) {
    return (dispatch, getState) =>
        Api.multipart(API_PROPOSALPOOL_EDIT, params, files).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_PROPOSALPOOL_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PROPOSALPOOL_EDIT_LOADING, { loading: false, data }));
        });
}

export function actionProposalStatus() {
    return (dispatch, getState) =>
        Api.get(API_OPPORTUNITY_STATUS).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_OPPORTUNITY_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OPPORTUNITY_STATUS_LOADING, { loading: false, data }));
        });
}

export function actionProposalQuoteRejectStatus() {
    return (dispatch, getState) =>
        Api.get(API_OPPORTUNITY_QUOTE_REJECT_STATUS).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_LOADING, { loading: false, data }));
        });
}

export function actionProposalLostStatus() {
    return (dispatch, getState) =>
        Api.get(API_OPPORTUNITY_LOST_STATUS).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_OPPORTUNITY_LOST_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OPPORTUNITY_LOST_STATUS_LOADING, { loading: false, data }));
        });
}

export function actionInstallment() {
    return (dispatch, getState) =>
        Api.get(API_INSTALLMENT).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_INSTALLMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_INSTALLMENT_LOADING, { loading: false, data }));
        });
}

export function actionProposalGetPlacements(params) {
    return (dispatch, getState) =>
        Api.post(API_OPPORTUNITY_PLACEMENTS_GET, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_OPPORTUNITY_PLACEMENTS_GET_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OPPORTUNITY_PLACEMENTS_GET_LOADING, { loading: false, data }));
        });
}

export function actionPolicyGroup(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_POLICY_TYPE, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_OPPORTUNITY_POLICY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_POLICY_LOADING, { loading: false, data }));
        });
}

export function actionInsuranceCompanyGroup(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_INSURER, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_INSURANCE_OPPORTUNITY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_INSURANCE_COMPANY_LOADING, { loading: false, data }));
        });
}

export function actionDepartmentInsuranceCompanyGroup(params) {
    return (dispatch, getState) =>
        Api.post(API_DEPARTMENT_WISE_GET_INSURER, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_DEPARTMENT_WISE_GET_INSURANCE_OPPORTUNITY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_DEPARTMENT_WISE_GET_INSURANCE_COMPANY_LOADING, { loading: false, data }));
        });
}

export function actionIntermediaryType() {
    return (dispatch, getState) =>
        Api.get(API_INTERMEDIARY_TYPE).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_INTERMEDIARY_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_INTERMEDIARY_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionGetBrokerName() {
    return (dispatch, getState) =>
        Api.get(API_GET_BROKER_NAME).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_OPPORTUNITY_BROKER_NAME_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OPPORTUNITY_BROKER_NAME_LOADING, { loading: false, data }));
        });
}

export function actionProbabilityGroup(params) {
    return (dispatch, getState) =>
        Api.get(API_MASTER_PROBABILITY).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_PROBABILITY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_PROBABILITY_LOADING, { loading: false, data }));
        });
}

export function actionOpportunityQuestionsList() {
    return (dispatch, getState) =>
        Api.get(API_OPPORTUNITY_QUESTION_LIST).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OPPORTUNITY_QUESTIONS_LIST_LOADING, { loading: false, data }));
        });
}
export function actionGetOpportunityQuestions(params) {
    return (dispatch, getState) =>
        Api.post(API_GET_OPPORTUNITY_QUESTION, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_OPPORTUNITY_QUESTIONS_LOADING, { loading: false, data }));
        });
}

export function actionClosureType() {
    return (dispatch, getState) =>
        Api.get(API_CLOSURE_TYPE).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_CLOSURE_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_CLOSURE_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionProposalPoolView(params) {
    return (dispatch, getState) =>
        Api.post(API_PROPOSALPOOL_VIEW, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_PROPOSALPOOL_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PROPOSALPOOL_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionGetAssignProductRM(params) {
    return (dispatch, getState) =>
        Api.post(API_ASSIGN_BY_OPPORTUNITY, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_ASSIGN_PRODUCT_RM_LOADING, { loading: false, data }));
        });
}

export function actionDeleteDocument(params) {
    return (dispatch, getState) =>
        Api.post(API_DELETE_OPPORTUNITY_DOCUMENT, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_DELETE_DOCUMENT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_DELETE_DOCUMENT_LOADING, { loading: false, data }));
        });
}

export function actionInsuranceListForSuccess(params) {
    return (dispatch, getState) =>
        Api.post(API_OPPORTUNITY_PLACEMENTS_GET, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_INSURANCE_SUCCESS_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_INSURANCE_SUCCESS_LIST_LOADING, { loading: false, data }));
        });
}