import { API_OPPORTUNITY_QUOTE_REJECT_STATUS } from '../../../api/constants';
import Api from '../../../api';
import { fetchSuccess, loading } from '../../../utils/action.util';

export const ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_SUCCESS = 'ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_SUCCESS';
export const ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_LOADING = 'ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_LOADING';

export function actionProposalQuoteRejectStatus() {
    return (dispatch, getState) =>
        Api.get(API_OPPORTUNITY_QUOTE_REJECT_STATUS).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_LOADING, { loading: false, data }));
        });
}