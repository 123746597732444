import { API_MASTER_GET_POLICY_TYPE, API_DEPARTMENT_WISE_GET_INSURER, API_MASTER_PROBABILITY, API_OPPORTUNITY_QUESTION_LIST, API_GET_OPPORTUNITY_QUESTION, API_ASSIGN_BY_OPPORTUNITY, API_GET_BROKER_NAME, API_MEETING_TYPE, API_SELECT_CUSTOMER, API_GET_TAG_USER } from '../../../api/constants';
import Api from '../../../api';
import { fetchSuccess, loading } from '../../../utils/action.util';

export const ACTION_MASTER_POLICY_LOADING = 'ACTION_MASTER_POLICY_LOADING';
export const ACTION_MASTER_POLICY_SUCCESS = 'ACTION_MASTER_POLICY_SUCCESS';

export const ACTION_MASTER_INSURANCE_COMPANY_LOADING = 'ACTION_MASTER_INSURANCE_COMPANY_LOADING';
export const ACTION_MASTER_INSURANCE_COMPANY_SUCCESS = 'ACTION_MASTER_INSURANCE_COMPANY_SUCCESS';

export const ACTION_MASTER_PROBABILITY_LOADING = 'ACTION_MASTER_PROBABILITY_LOADING';
export const ACTION_MASTER_PROBABILITY_SUCCESS = 'ACTION_MASTER_PROBABILITY_SUCCESS';

export const ACTION_OPPORTUNITY_QUESTIONS_LIST_LOADING = 'ACTION_OPPORTUNITY_QUESTIONS_LIST_LOADING';
export const ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS = 'ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS';

export const ACTION_GET_OPPORTUNITY_QUESTIONS_LOADING = 'ACTION_GET_OPPORTUNITY_QUESTIONS_LOADING';
export const ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS = 'ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS';

export const ACTION_GET_ASSIGN_PRODUCT_RM_LOADING = 'ACTION_GET_ASSIGN_PRODUCT_RM_LOADING';
export const ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS = 'ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS';

export const ACTION_GET_BROKER_NAME_LOADING = 'ACTION_GET_BROKER_NAME_LOADING';
export const ACTION_GET_BROKER_NAME_SUCCESS = 'ACTION_GET_BROKER_NAME_SUCCESS';

export const ACTION_GET_TAG_USER_LOADING = 'ACTION_GET_TAG_USER_LOADING';
export const ACTION_GET_TAG_USER_SUCCESS = 'ACTION_GET_TAG_USER_SUCCESS';

export const ACTION_API_CLIENT_TYPE_LOADING = 'ACTION_API_CLIENT_TYPE_LOADING';
export const ACTION_API_CLIENT_TYPE_SUCCESS = 'ACTION_API_CLIENT_TYPE_SUCCESS';

export const ACTION_CLIENT_NAME_LOADING = 'ACTION_CLIENT_NAME_LOADING';
export const ACTION_CLIENT_NAME_SUCCESS = 'ACTION_CLIENT_NAME_SUCCESS';

export function actionPolicyGroup(params) {
    return (dispatch, getState) =>
        Api.post(API_MASTER_GET_POLICY_TYPE, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_POLICY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_POLICY_LOADING, { loading: false, data }));
        });
}

export function actionInsuranceCompanyGroup(params) {
    return (dispatch, getState) =>
        Api.post(API_DEPARTMENT_WISE_GET_INSURER, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_INSURANCE_COMPANY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_INSURANCE_COMPANY_LOADING, { loading: false, data }));
        });
}

export function actionProbabilityGroup(params) {
    return (dispatch, getState) =>
        Api.get(API_MASTER_PROBABILITY).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_MASTER_PROBABILITY_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_MASTER_PROBABILITY_LOADING, { loading: false, data }));
        });
}

export function actionOpportunityQuestionsList() {
    return (dispatch, getState) =>
        Api.get(API_OPPORTUNITY_QUESTION_LIST).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_OPPORTUNITY_QUESTIONS_LIST_LOADING, { loading: false, data }));
        });
}
export function actionGetOpportunityQuestions(params) {
    return (dispatch, getState) =>
        Api.post(API_GET_OPPORTUNITY_QUESTION, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_OPPORTUNITY_QUESTIONS_LOADING, { loading: false, data }));
        });
}
export function actionGetAssignProductRM(params) {
    return (dispatch, getState) =>
        Api.post(API_ASSIGN_BY_OPPORTUNITY, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_ASSIGN_PRODUCT_RM_LOADING, { loading: false, data }));
        });
}

export function actionGetBrokerName() {
    return (dispatch, getState) =>
        Api.get(API_GET_BROKER_NAME).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_GET_BROKER_NAME_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_BROKER_NAME_LOADING, { loading: false, data }));
        });
}

export function actionMeetingType() {
    return (dispatch, getState) =>
        Api.get(API_MEETING_TYPE).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_API_CLIENT_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_API_CLIENT_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionSelectCustomer(params) {
    return (dispatch, getState) =>
        Api.post(API_SELECT_CUSTOMER, params).then((res) => {
            const data = res;
            // PRINT('ACTION SELECT CUSTOMER RESPONSE', data)
            dispatch(fetchSuccess(ACTION_CLIENT_NAME_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_CLIENT_NAME_LOADING, { loading: false, data }));
        });
}

export function actionTagUserList(params) {
    return (dispatch, getState) =>
        Api.get(API_GET_TAG_USER, params).then((res) => {
            const data = res;
            // PRINT('ACTION SELECT CUSTOMER RESPONSE', data)
            dispatch(fetchSuccess(ACTION_GET_TAG_USER_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_GET_TAG_USER_LOADING, { loading: false, data }));
        });
}