import React, { useState, useEffect } from 'react'
import { DateBox } from 'devextreme-react';
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    Scrolling,
    SearchPanel,
} from "devextreme-react/data-grid";
import Swal from 'sweetalert2';
import CustomStore from 'devextreme/data/custom_store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Controller, useForm } from 'react-hook-form';
import ErrorLabel from "../../../../../../component/form/error_label";
import { UNAUTHORIZED } from '../../../../../../constants';
import $ from 'jquery';
import dateFormat from 'dateformat';
import { withAuth } from 'react-auth-guard';
import { TagBox } from 'devextreme-react/tag-box';
import ArrayStore from 'devextreme/data/array_store';
import _ from "lodash";
import { actionLeaveReport, ACTION_LEAVE_REPORT_SUCCESS } from './action';
import { API_LEAVE_REPORT } from '../../../../../../api/constants';
import Api from "../../../../../../api";
import { isNotEmpty } from '../../../../../../utils';
import { actionMasterEmployeeDetails, ACTION_LEAVE_MASTER_EMPLOYEE_DETAILS_SUCCESS } from './action';
import moment from 'moment';
const LeavePage = (props) => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionMasterEmployeeDetails({ user_id: props.auth.getUser().user_id });
        // props.actionLeaveReport({
        //     from_date: moment(fromDate).format('YYYY-MM-DD'),
        //     to_date: moment(toDate).format('YYYY-MM-DD'),
        // });
    }
    useEffect(initialRender, []);

    const [employeeDetails, setEmployeeDetails] = useState([])
    const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(dateFormat(new Date(), "mm/dd/yyyy"));
    const [employeeId, setEmployeeId] = useState(null);
    const [employeeReport, setEmployeeReport] = useState([]);

    const columns = [
        // { dataField: "application_date", caption: "Application date", visible: true },
        { dataField: "user_id", caption: "User Id", visible: true },
        { dataField: "employee_id", caption: "Employee id", visible: true },
        { dataField: "full_name", caption: "Full name", visible: true },
        { dataField: "designation", caption: "Designation", visible: true },
        { dataField: "reporting_to", caption: "Reporting To", visible: true },
        { dataField: "city", caption: "City", visible: true },
        { dataField: "user_departments", caption: "Department", visible: false },
        { dataField: "user_functions", caption: "Function", visible: false },
        { dataField: "user_companies", caption: "Entity", visible: false },
        { dataField: "leave_type", caption: "Leave type", visible: true },
        { dataField: "grade", caption: "Grade", visible: true },
        { dataField: "from_date_formated", caption: "From date", visible: true },
        { dataField: "from_date", caption: "From date", visible: false },
        { dataField: "to_date_formated", caption: "To date", visible: true },
        { dataField: "to_date", caption: "To date", visible: false },
        { dataField: "approval_date_formated", caption: "Approval date", visible: true },
        { dataField: "approval_date", caption: "Approval date", visible: false },
        { dataField: "rejected_date_formated", caption: "Reject date", visible: true },
        { dataField: "rejected_date", caption: "Reject date", visible: false },
        { dataField: "status", caption: "Status", visible: true },
        { dataField: "approved_by", caption: "Approved by", visible: true },
        { dataField: "remark", caption: "Remark", visible: true },
        { dataField: "reason", caption: "Reason", visible: true },
    ];

    const store = new CustomStore({
        load(loadOptions) {
            let params = '?';
            [
                'skip',
                'take',
                'requireTotalCount',
                'requireGroupCount',
                'sort',
                'filter',
                'totalSummary',
                'group',
                'groupSummary',
            ].forEach((i) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
            });
            params = params.slice(0, -1);

            var request = {};

            request = {
                from_date: moment(fromDate).format('YYYY-MM-DD'),
                to_date: moment(toDate).format('YYYY-MM-DD'),
                employee_id: employeeId,
                params: params
            };

            return Api.post(API_LEAVE_REPORT + params, request).then((res) => {
                $("#progress-loader").addClass("hidden");

                return res.response;
            }).catch((reason) => {
                $("#progress-loader").addClass("hidden");
            });
        },
    });

    const onMasterEmployeeDetailsUpdate = () => {
        const { MasterEmployeeDetails } = props;
        var MasterEmployeeDetailsResponse = Object.assign({}, MasterEmployeeDetails);
        if (MasterEmployeeDetailsResponse.result === true) {
            setEmployeeDetails(MasterEmployeeDetailsResponse.response);
            $("#progress-loader").addClass("hidden");
        } else {
            $("#progress-loader").addClass("hidden");
            switch (MasterEmployeeDetailsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onMasterEmployeeDetailsUpdate, [props.MasterEmployeeDetails]);

    const onLeaveReportUpdate = () => {
        const { LeaveReport } = props;
        var LeaveReportResponse = Object.assign({}, LeaveReport);
        $("#leave-report").prop("disabled", false);
        if (LeaveReportResponse.result === true) {
            setEmployeeReport(LeaveReportResponse.response);
            $("#leave-report-loader").addClass("hidden");
        } else {
            $("#leave-report-loader").addClass("hidden");
            switch (LeaveReportResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onLeaveReportUpdate, [props.LeaveReport]);

    const renderFromDate = (cell) => {
        return <div>{cell?.data?.from_date_formated != null ? moment(cell?.data?.from_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderToDate = (cell) => {
        return <div>{cell?.data?.to_date_formated != null ? moment(cell?.data?.to_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderApprovalDate = (cell) => {
        return <div>{cell?.data?.approval_date_formated != null ? moment(cell?.data?.approval_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderRejectDate = (cell) => {
        return <div>{cell?.data?.rejected_date_formated != null ? moment(cell?.data?.rejected_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "from_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderFromDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "to_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderToDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "approval_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderApprovalDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "rejected_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderRejectDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    const onSubmit = (data) => {
        $("#leave-report-loader").removeClass("hidden");
        $("#leave-report").prop("disabled", true);
        setEmployeeReport([])
        props.actionLeaveReport({
            from_date: moment(fromDate).format('YYYY-MM-DD'),
            to_date: moment(toDate).format('YYYY-MM-DD'),
            employee_id: employeeId
        })
    }

    return (
        <>
            <section>
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 pb-1">
                        <div className="row d-flex align-items-center">
                            <div className="col-12 col-md-6 col-lg-6 my-auto">
                                <h5 className="content-header-title text-capitalize mb-0">Leave Report</h5>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="flex-column">
                                    <div className="d-flex flex-row w-100 justify-content-start justify-content-lg-end">
                                        <button
                                            className="btn btn-secondary btn-sm mr-1 mb-1 mb-md-0 mb-lg-0 shadow round"
                                            onClick={() => {
                                                props.history.goBack();
                                            }}
                                        >
                                            Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">From Date</label>
                                            <Controller
                                                name="from_date"
                                                control={control}
                                                defaultValue={fromDate}
                                                render={({ field }) =>
                                                    <DateBox
                                                        defaultValue={new Date()}
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        acceptCustomValue={false}
                                                        stylingMode="underlined"
                                                        pickerType="calendar"
                                                        placeholder="From Date"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        value={field.value}
                                                        onValueChange={(value) => {
                                                            var objFromDate = moment(value).format('YYYY-MM-DD');
                                                            var objToDate = moment(toDate).format('YYYY-MM-DD');
                                                            if (objToDate < objFromDate) {
                                                                Swal.fire({
                                                                    title: 'Error',
                                                                    text: "From date can't be greater than To date",
                                                                    icon: 'error'
                                                                }).then(success => {

                                                                })
                                                            } else {
                                                                field.onChange(moment(value).format('YYYY-MM-DD'));
                                                                setFromDate(moment(value).format('YYYY-MM-DD'));

                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">To Date</label>
                                            <Controller
                                                className="flex-column"
                                                name="to_date"
                                                control={control}
                                                defaultValue={toDate}
                                                render={({ field }) =>
                                                    <DateBox
                                                        defaultValue={new Date()}
                                                        calendarOptions={{
                                                            showTodayButton: true,
                                                        }}
                                                        acceptCustomValue={false}
                                                        stylingMode="underlined"
                                                        pickerType="calendar"
                                                        placeholder="To Date"
                                                        type="date"
                                                        displayFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        value={field.value}
                                                        onValueChange={(value) => {
                                                            var objFromDate = moment(fromDate).format('YYYY-MM-DD');
                                                            var objToDate = moment(value).format('YYYY-MM-DD');
                                                            if (objToDate < objFromDate) {
                                                                Swal.fire({
                                                                    title: 'Error',
                                                                    text: "To date must be greater than from date",
                                                                    icon: 'error'
                                                                }).then(success => {

                                                                })
                                                            } else {
                                                                field.onChange(moment(value).format('YYYY-MM-DD'));
                                                                setToDate(moment(value).format('YYYY-MM-DD'));

                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label className="label-control primary">Employee</label>
                                            <Controller
                                                name="employee_id"
                                                control={control}
                                                render={({ field }) =>
                                                    <TagBox
                                                        {...field}
                                                        dataSource={
                                                            new ArrayStore({
                                                                data: employeeDetails,
                                                                key: 'id',


                                                            })
                                                        }
                                                        dropDownOptions={{
                                                            onInitialized: () => {
                                                                console.log('initial')
                                                            },
                                                            onContentReady: () => {
                                                                console.log('content ready')
                                                            },
                                                            onShowing: () => {
                                                                console.log('showing')
                                                            },
                                                            onShown: () => {
                                                                console.log('shown')
                                                            }
                                                        }}
                                                        allowFiltering={false}
                                                        deferRendering={true}
                                                        displayExpr='name'
                                                        placeholder="Select Employee Name"
                                                        searchEnabled={true}
                                                        valueExpr='id'
                                                        searchMode="contains"
                                                        maxDisplayedTags={2}
                                                        showMultiTagOnly={false}
                                                        className="form-control"
                                                        stylingMode="underlined"
                                                        value={field.value}
                                                        onValueChanged={({ value }) => {
                                                            if (value != null || value !== undefined || value != '') {
                                                                field.onChange(value);
                                                                setEmployeeId(value);
                                                            }
                                                        }}
                                                        showClearButton={true}
                                                    />
                                                }
                                            />

                                            {errors.employee_id && <ErrorLabel message={errors.employee_id?.message} />}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <div className="form-actions mt-1 border-0 float-none">
                                            <button
                                                id="leave-report"
                                                type='submit'
                                                className="btn btn-primary mr-8  btn-md mb-md-0 mb-lg-0 shadow round"
                                            >Search
                                                <span id="leave-report-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="col-12 col-lg-12 col-md-12">
                            <DataGrid
                                dataSource={store}
                                showBorders={true}
                                columnAutoWidth={true}
                                height={500}
                                remoteOperations={true}
                                export={{
                                    fileName: "Leave Report",
                                    enabled: true,
                                    allowExportSelectedData: false,
                                    excelFilterEnabled: true,
                                }}
                                onExporting={(event) => {
                                    event.component.beginUpdate();
                                    event.component.columnOption("user_functions", "visible", true)
                                    event.component.columnOption("user_departments", "visible", true)
                                    event.component.columnOption("user_companies", "visible", true)
                                    // 
                                    event.component.columnOption("from_date_formated", "visible", false)
                                    event.component.columnOption("from_date", "visible", true)
                                    event.component.columnOption("to_date_formated", "visible", false)
                                    event.component.columnOption("to_date", "visible", true)
                                    event.component.columnOption("approval_date_formated", "visible", false)
                                    event.component.columnOption("approval_date", "visible", true)
                                    event.component.columnOption("rejected_date_formated", "visible", false)
                                    event.component.columnOption("rejected_date", "visible", true)
                                    event.component.columnOption("reporting_to", "visible", true)
                                }}
                                onExported={(event) => {
                                    event.component.columnOption("user_functions", "visible", false)
                                    event.component.columnOption("user_departments", "visible", false)
                                    event.component.columnOption("user_companies", "visible", false)
                                    event.component.columnOption("from_date_formated", "visible", true)
                                    event.component.columnOption("from_date", "visible", false)
                                    event.component.columnOption("to_date_formated", "visible", true)
                                    event.component.columnOption("to_date", "visible", false)
                                    event.component.columnOption("approval_date_formated", "visible", true)
                                    event.component.columnOption("approval_date", "visible", false)
                                    event.component.columnOption("rejected_date_formated", "visible", true)
                                    event.component.columnOption("rejected_date", "visible", false)
                                    event.component.columnOption("reporting_to", "visible", true)
                                    event.component.endUpdate();
                                }}
                            >
                                <SearchPanel
                                    visible={true}
                                    searchVisibleColumnsOnly={true}
                                />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                <Scrolling mode="virtual" />
                                {renderColumns()}
                                <Pager
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showNavigationButtons={true}
                                    allowedPageSizes={[4, 6, 10]}
                                />
                                <Paging
                                    defaultPageIndex={0}
                                    defaultPageSize={10}
                                    enabled={true}
                                />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var MasterEmployeeDetails = null;
    var LeaveReport = null;

    if (
        state.LeaveReportReducer != null &&
        Object.keys(state.LeaveReportReducer).length > 0 &&
        state.LeaveReportReducer.constructor === Object
    ) {
        switch (state.LeaveReportReducer.tag) {
            case ACTION_LEAVE_MASTER_EMPLOYEE_DETAILS_SUCCESS:
                MasterEmployeeDetails = Object.assign({}, state.LeaveReportReducer.data);
                delete state.LeaveReportReducer;
                return { MasterEmployeeDetails };
            case ACTION_LEAVE_REPORT_SUCCESS:
                LeaveReport = Object.assign({}, state.LeaveReportReducer.data);
                delete state.LeaveReportReducer;
                return { LeaveReport };
            default:
        }
    }

    return {
        MasterEmployeeDetails,
        LeaveReport,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMasterEmployeeDetails, actionLeaveReport }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(LeavePage))
