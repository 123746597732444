import React, { useState, useEffect } from 'react';
import { FiEye } from 'react-icons/fi';
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { BsTable } from 'react-icons/bs';
import { RiLayoutGridFill } from 'react-icons/ri';
import { verifyPermission } from '../../../../../../utils';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UNAUTHORIZED } from '../../../../../../constants';
import $ from 'jquery';
import moment from "moment";
import { actionTaskPoolList, ACTION_TASK_POOL_LIST_SUCCESS } from './action';
import _ from 'lodash';

const TaskPoolList = (props) => {

    const { match } = props;

    const [taskPool, setTaskPool] = useState([]);
    const [selectedTemplate, setselectedTemplate] = useState('LIST');

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "full_name", caption: "Assign By", visible: true },
        { dataField: "task", caption: " Task Name", visible: true },
        { dataField: "description", caption: "Description", visible: true },
        { dataField: "priority", caption: "Priority", dataType: "string", visible: true },
        { dataField: "type", caption: " Type", visible: true },
        { dataField: "delivery_date", caption: " Delivery Date", visible: false },
        { dataField: "delivery_date_formated", caption: " Delivery Date", visible: true },
        { dataField: "status_name", caption: "Status", visible: true },

    ]);

    const initialRender = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        $("#progress-loader").removeClass("hidden");
        props.actionTaskPoolList({});
    }
    useEffect(initialRender, []);

    const onTaskPoolListUpdate = () => {
        const { TaskPoolList } = props;
        var TaskPoolListResponse = Object.assign({}, TaskPoolList);
        if (TaskPoolListResponse.result === true) {

            setColumns([
                { dataField: "Action", caption: "Action", type: "button" },
                { dataField: "full_name", caption: "Assign By", visible: true },
                { dataField: "task", caption: " Task Name", visible: true },
                { dataField: "description", caption: "Description", visible: true },
                { dataField: "priority", caption: "Priority", dataType: "string", visible: true },
                { dataField: "type", caption: " Type", visible: true },
                { dataField: "delivery_date", caption: " Delivery Date", visible: false },
                { dataField: "delivery_date_formated", caption: " Delivery Date", visible: true },
                { dataField: "status_name", caption: "Status", visible: true },
            ])

            setTaskPool(TaskPoolListResponse.response);
            $("#progress-loader").addClass("hidden");
        } else {
            $("#progress-loader").addClass("hidden");
            switch (TaskPoolListResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onTaskPoolListUpdate, [props.TaskPoolList])

    const renderStatusCell = (cell) => {
        switch (cell.data.status_name) {
            case 'Open':
                return <div className={"badge badge-success round px-1 cursor-pointer"}>{cell.data.status_name}</div>;
            default:
                return <></>
        }
    }

    const renderTaskNameCell = (cell) => {
        return <div>{(cell.data.type === 'asset' ? cell.data.name : cell.data.task)}</div>;
    }

    const renderTypeCell = (cell) => {
        return <div>{_.capitalize(cell.data.type)}</div>
    }

    const renderDeliveryDate = (cell) => {
        return <div>{cell?.data?.delivery_date_formated != null ? moment(cell?.data?.delivery_date_formated).format('DD/MM/YYYY') : ''}</div>
    }
    const renderPriority = (cell) => {
        return <div>{cell?.data?.user_request?.priority != null ? (cell?.data?.user_request?.priority ) : '-'}</div>
    }

    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-right col-12 col-md-6 col-lg-6">
                    <ul class="nav nav-pills float-right">
                        {
                            taskPool != null && taskPool.length > 0 ?
                                <li class="nav-item mr-1">
                                    <button className="btn btn-primary btn-md font-small-3 shadow round"
                                        onClick={(event) => {
                                            if (selectedTemplate === "LIST") {
                                                setselectedTemplate("GRID")
                                            } else {
                                                setselectedTemplate("LIST")
                                            }
                                        }}
                                    >
                                        {
                                            selectedTemplate === "LIST" ?
                                                <RiLayoutGridFill />

                                                : <BsTable />
                                        }
                                    </button>
                                </li>
                                : <></>
                        }
                    </ul>
                </div>
            </>
        )

    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission("VIEW_TASK_POOL") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/view`, state: { id: params.data.id } })
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                    </Column>
                );

            } else {
                if (objColumn.dataField === "status_name") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderStatusCell}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "task") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderTaskNameCell}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
                else if (objColumn.dataField === "delivery_date_formated") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderDeliveryDate}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "type") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderTypeCell}
                            visible={objColumn.visible}
                        ></Column>
                    );
                } else if (objColumn.dataField === "priority") {
                    arrColumns.push(
                        <Column
                            dataField="user_request.priority"
                            caption={objColumn.caption}
                            cellRender={renderPriority}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
                else {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
            }
        });
        return arrColumns;
    };

    const renderListUI = () => {
        return (
            <DataGrid
                dataSource={taskPool}
                columnAutoWidth={true}
                showBorders={true}
                export={{
                    fileName: "Task Pool Details",
                    enabled: true,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
                onExporting={(event) => {
                    event.component.beginUpdate();
                    event.component.columnOption("delivery_date_formated", "visible", false)
                    event.component.columnOption("delivery_date", "visible", true)
                    event.component.columnOption("priority", "visible", true)
                }}
                onExported={(event) => {
                    event.component.columnOption("delivery_date_formated", "visible", true)
                    event.component.columnOption("delivery_date", "visible", false)
                    event.component.columnOption("priority", "visible", true)
                    event.component.endUpdate();
                }}
                onToolbarPreparing={(e) => {
                    e.toolbarOptions.items.unshift({
                        location: 'before',

                    })
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderColumns()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />
            </DataGrid>
        )
    }
    const renderGridUI = () => {
        var arrGridColumns = []
        if (taskPool != null && taskPool.length > 0) {
            taskPool.map((taskPool, index) => (
                arrGridColumns.push(
                    <div className="col-12 col-md-6 col-lg-6" key={index}>
                        <div className="card border">
                            <div className={`card-body px-0 py-1`}>
                                <div className="d-flex flex-row px-1">
                                    <div className="profile-img-container-sm bg-light rounded-circle mr-1 border">
                                        <h6 class="name-preffix-sm"> {String(taskPool.full_name).substring(0, 2).toUpperCase()}</h6>
                                    </div>
                                    <div>
                                        <p className={`card-text-esc font-medium-1 mb-0 text-capitalize`}>{taskPool.full_name}</p>
                                    </div>
                                    <div className={`ml-auto font-small-2`}>
                                        <div>
                                            <span>Delivery Date:</span> {taskPool.delivery_date}
                                        </div>
                                        <div>
                                            <span>{taskPool.status}</span>
                                        </div>
                                    </div>
                                </div>
                                <hr className={`my-half`} />
                                <div className="d-flex flex-row px-1">
                                    <div className={"flex-column w-50"}>
                                        <p className={`card-text-esc font-small-3 mb-0 text-capitalize`}>Task Name</p>
                                        <p className={`font-small-2 small mb-0 card-text-esc`}>{taskPool.task}</p>
                                    </div>
                                    <div className={"flex-column w-50"}>
                                        <p className={`card-text-esc font-small-3 mb-0 text-capitalize`}>Task Type</p>
                                        <p className={`font-small-2 small mb-0 card-text-esc`}>{taskPool.type}</p>
                                    </div>
                                    <div className={"align-self-end d-inline-flex"}>
                                        {
                                            verifyPermission("VIEW_TASK") === false ?
                                                <FiEye size={14} className={`primary`} cursor="pointer"
                                                    onClick={() => {
                                                        props.history.push({ pathname: `${match.url}/view`, state: { id: taskPool.id } })
                                                    }}
                                                />
                                                : <> </>

                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )
            ))
        }
        return arrGridColumns
    }

    return (
        <>
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0">Task Pool List {taskPool.length === 0 ? '' : `(${taskPool.length})`}</h5>
                </div>
                {renderHeaderButtonUI()}
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            {
                                selectedTemplate === "LIST" ?
                                    renderListUI()
                                    : <></>
                            }
                        </div>
                        {
                            selectedTemplate === "GRID" ?
                                renderGridUI()
                                : <></>
                        }


                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    var TaskPoolList = null;

    if (
        state.TaskPoolListReducer != null &&
        Object.keys(state.TaskPoolListReducer).length > 0 &&
        state.TaskPoolListReducer.constructor === Object
    ) {
        switch (state.TaskPoolListReducer.tag) {
            case ACTION_TASK_POOL_LIST_SUCCESS:
                TaskPoolList = Object.assign({}, state.TaskPoolListReducer.data);
                delete state.TaskPoolListReducer;
                return { TaskPoolList };
            default:
        }
    }

    return {
        TaskPoolList,
    }

}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionTaskPoolList }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(TaskPoolList)