import React, { useEffect, useState } from 'react';
import { withAuth } from 'react-auth-guard';
import ScreenTitle from '../../../../../../component/screen_title';
import TaskStatus from '../../../../../../component/popups/task_approval';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { UNAUTHORIZED } from '../../../../../../constants';
import { actionTaskView, ACTION_TASK_VIEW_SUCCESS } from './action';
import { downloadFile, generateInitialLettersString } from '../../../../../../utils';
import RequestHistory from '../../../../../../component/widgets/request_history';
import { BiCalendar, BiCheckCircle } from "react-icons/bi";
import { BsArrowRight, BsXCircle } from "react-icons/bs";
import { IoMdAirplane } from "react-icons/io";
import dateFormat from 'dateformat';
import DocumentIframe from '../../../../../../component/popups/document_iframe';

const TasksView = (props) => {

    const [showChangeStatus, setShowChangeStatus] = useState(false);
    const [taskView, setTaskView] = useState({});
    const [requestHistory, setRequestHistory] = useState(null);
    const [showActionButton, setShowActionButton] = useState(false);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)

    const initialRender = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionTaskView({
            id: props.location.state.id,
        });
    }
    useEffect(initialRender, []);

    const onTaskViewUpdate = () => {
        const { TaskView } = props;
        if (TaskView != null) {
            var TaskViewResponse = Object.assign({}, TaskView);
            if (TaskViewResponse.result === true) {

                setShowActionButton(false);

                setTaskView(TaskViewResponse.response);

                if (TaskViewResponse.response.request_status.length > 0) {
                    setRequestHistory(TaskViewResponse.response.request_status);
                }

                if (props.auth.getUser().user_id === TaskViewResponse.response.user_id && props.auth.getUser().user_id === TaskViewResponse.response.assign_to) {
                    switch (TaskViewResponse.response.status) {
                        case 'open':
                        case 'in-progress':
                            setShowActionButton(true)
                            break;
                    }
                } else {
                    // Owner is Login
                    if (props.auth.getUser().user_id === TaskViewResponse.response.user_id) {
                        switch (TaskViewResponse.response.status) {
                            case 'open':
                                if (TaskViewResponse.response.type === 'asset' || TaskViewResponse.response.type === 'travel') {
                                    setShowActionButton(false)
                                } else {
                                    setShowActionButton(true)
                                }
                                break;
                            case 'to_review':
                                setShowActionButton(true)
                                break;
                        }
                    }
                    // User is Login
                    if (props.auth.getUser().user_id === TaskViewResponse.response.assign_to) {
                        switch (TaskViewResponse.response.status) {
                            case 'open':
                            case 're-open':
                                setShowActionButton(true)
                                break;
                            case 'in-progress':
                                setShowActionButton(true)
                                break;
                        }
                    }

                }

            } else {
                switch (TaskViewResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onTaskViewUpdate, [props.TaskView])
    return (
        <>
            <ScreenTitle title="Task Detail" />
            <div id="user-profile">
                <div class="row">
                    <div class="col-12">
                        <div class="card profile-with-cover">
                            <div class="card-img-top img-fluid bg-cover height-75" style={{ backgroundColor: '#E3066F88' }}></div>
                            <div class="media profil-cover-details w-100">
                                <div class="media-left pl-2 pt-2">
                                    <a className="profile-image">
                                        {
                                            (taskView != null && taskView.user != null && taskView.user.profile_pic != null) ?
                                                <img
                                                    src={taskView.user.profile_pic}
                                                    className="rounded-circle img-border"
                                                    alt="Card"
                                                    width={100}
                                                    height={100}
                                                    style={{ marginTop: '-65px', border: '5px solid #FFF' }}
                                                />
                                                :
                                                <>
                                                    <div
                                                        className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-large-2"
                                                        style={{ marginTop: '-60px', border: '5px solid #FFF', height: 100, width: 100 }}
                                                    >
                                                        {generateInitialLettersString(String(taskView.full_name))}

                                                    </div>
                                                </>
                                        }
                                    </a>
                                </div>
                                <div class="media-body pt-1 px-2 pb-2">
                                    <div class="row">
                                        <div class="col">
                                            <h3 class="card-title mb-0">
                                                {
                                                    taskView != null && taskView.full_name != null ?
                                                        taskView.full_name : '-'
                                                }
                                            </h3>
                                            <button className="btn btn-sm btn-success text-bold-500 round">
                                                {
                                                    taskView != null && taskView.designation_name != null ?
                                                        taskView.designation_name : '-'
                                                }
                                            </button>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-6">
                                            <h6 className="font-small-2 text-bold-700">Status</h6>
                                            <div className="btn btn-primary round btn-sm px-1 mb-half">
                                                <h6 className="font-small-3 text-bold-500 my-0">
                                                    {
                                                        taskView != null && taskView.status_name != null ?
                                                            taskView.status_name : '-'
                                                    }
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Task Type</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        taskView != null && taskView.type != null ?
                                            _.capitalize(taskView.type) : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Task Name</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        taskView != null && taskView.task != null ?
                                            taskView.user_request_id != null ? taskView.user_request.name :
                                                taskView.task : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Create Date</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        taskView != null && taskView.created_date != null ?
                                            taskView.created_date : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">
                                    {
                                        taskView.type === 'it' ?
                                            'Raised Date'
                                            :
                                            'Delivery Date'
                                    }
                                </h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        taskView != null && taskView.delivery_date != null ?
                                            taskView.delivery_date : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Description</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        taskView != null && taskView.description != null ?
                                            taskView.description : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Priority</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        taskView != null && taskView.user_request?.priority  != null ?
                                        taskView.user_request?.priority : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Assigned By</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">

                                    {
                                        taskView != null && taskView.full_name != null ?
                                            taskView.full_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Assigned To</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">

                                    {
                                        taskView != null && taskView.assign_to_full_name != null ?
                                            taskView.assign_to_full_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Remark</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        taskView != null && taskView.remark != null ?
                                            taskView.remark : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Upload Document</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        (taskView != null && taskView.travel_document != null) ?
                                                                    
                                    // <a className='primary' target="_blank" href={taskView.travel_document}>Click Here</a> : <>-</>
                                            <>
                                                <a className="primary"
                                                    onClick={() => {
                                                        if (taskView.travel_document?.split('.').pop() == 'pdf' ||
                                                            taskView.travel_document?.split('.').pop() == 'PDF' ||
                                                            taskView.travel_document?.split('.').pop() == 'png' ||
                                                            taskView.travel_document?.split('.').pop() == 'jpg' ||
                                                            taskView.travel_document?.split('.').pop() == 'jpeg' ||
                                                            taskView.travel_document?.split('.').pop() == 'gif'
                                                        ) {
                                                            setSelectedDocumentIndex(taskView.travel_document)
                                                            setShowDocumentDetails(true);
                                                            setExtension(taskView.travel_document?.split('.').pop())
                                                            setDocumentDetails(taskView)
                                                        } else {
                                                            downloadFile(taskView.travel_document);
                                                        }
                                                    }}
                                                >
                                                    Click Here
                                                </a>
                                                <a id="downloadLink" className="d-none"></a>
                                            </>
                                            :
                                            <>-</>
                                    }
                                </h4>
                            </div>
                        </div>
                        {
                            taskView.travel != null && taskView.travel.length > 0 ?
                                taskView.travel.map((travel, indexTravel) => (
                                    <div className="ticket-outer p-1 my-2 shadow-sm">
                                        <div className="bg-white ticket-view pl-1">
                                            <div className="row">
                                                <div className="col-12 col-md-2 col-lg-2 date">
                                                    <p>
                                                        <span>
                                                            <IoMdAirplane size={24} />
                                                        </span>
                                                        <time>
                                                            <span>{dateFormat(travel.travel_date, "dd")}</span>
                                                            <span>{dateFormat(travel.travel_date, "mmm yyyy")}</span>
                                                        </time>
                                                    </p>
                                                </div>
                                                <div className="col-12 col-md-10 col-lg-10 px-3 py-1">
                                                    <div className="row px-0 align-items-center mb-half">
                                                        <div className="font-weight-bold col-md-auto">
                                                            <p className="">From</p>
                                                            {travel.arrival}
                                                        </div>
                                                        <span className="d-md-flex col-md-auto">
                                                            <div class="hr-left ml-2 ml-md-0"></div>
                                                            <span class="d-none d-md-block mx-half">
                                                                <BsArrowRight size={18} />
                                                            </span>
                                                            <div class="hr-right ml-2 ml-md-0"></div>
                                                        </span>
                                                        <div className="font-weight-bold col-md-auto">
                                                            <p className="">To</p>
                                                            {travel.departure}
                                                        </div>
                                                    </div>
                                                    <div class="px-0">
                                                        <BiCalendar size={16} />
                                                        <time>
                                                            <span class="ml-half">{dateFormat(travel.travel_date, "dd/mm/yyyy")}</span>
                                                        </time>
                                                    </div>
                                                    <div class="px-0 mt-half">
                                                        <p className="text-bold-700 mb-0">Travel Arrangement</p>
                                                    </div>
                                                    <div class="px-0 mt-half">
                                                        <div className="d-flex flex-row">
                                                            <div className="flex-comumn mr-1">
                                                                {
                                                                    travel.flight == 1 ?
                                                                        <>
                                                                            <BiCheckCircle size={22} className="mr-half text-primary" />
                                                                            Flight
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <BsXCircle size={16} className="mr-half" />
                                                                            Flight
                                                                        </>
                                                                }
                                                            </div>
                                                            <div className="flex-comumn mr-1">
                                                                {
                                                                    travel.hotel == 1 ?
                                                                        <>
                                                                            <BiCheckCircle size={22} className="mr-half text-primary" />
                                                                            Hotel
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <BsXCircle size={16} className="mr-half" />
                                                                            Hotel
                                                                        </>
                                                                }
                                                            </div>
                                                            <div className="flex-comumn mr-1">
                                                                {
                                                                    travel.car == 1 ?
                                                                        <>
                                                                            <BiCheckCircle size={22} className="mr-half text-primary" />
                                                                            Rental Car
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <BsXCircle size={16} className="mr-half" />
                                                                            Rental Car
                                                                        </>
                                                                }
                                                            </div>
                                                            <div className="flex-comumn mr-1">
                                                                {
                                                                    travel.train_bus == 1 ?
                                                                        <>
                                                                            <BiCheckCircle size={22} className="mr-half text-primary" />
                                                                            Train Bus
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <BsXCircle size={16} className="mr-half" />
                                                                            Train Bus
                                                                        </>
                                                                }
                                                            </div>
                                                            <div className="flex-comumn mr-1">
                                                                {
                                                                    travel.visa == 1 ?
                                                                        <>
                                                                            <BiCheckCircle size={22} className="mr-half text-primary" />
                                                                            Visa
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <BsXCircle size={16} className="mr-half" />
                                                                            Visa
                                                                        </>
                                                                }
                                                            </div>
                                                            <div className="flex-comumn mr-1">
                                                                {
                                                                    travel.insurance == 1 ?
                                                                        <>
                                                                            <BiCheckCircle size={22} className="mr-half text-primary" />
                                                                            Travel Insurance
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <BsXCircle size={16} className="mr-half" />
                                                                            Travel Insurance
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        travel.travellers != null && travel.travellers.length > 0 ?
                                                            <>
                                                                <p className="text-bold-700 pt-half mb-0">Travel With</p>
                                                                {
                                                                    travel.travellers != null ?
                                                                        _.map(travel.travellers, 'full_name').join(", ") : '-'
                                                                }
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :
                                <></>
                        }
                        {
                            taskView.type === 'it' ?
                                <div className="row pt-2">
                                    <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                        <h4 className="text-uppercase font-small-3 mb-0">Document</h4>
                                    </div>
                                    <div className="col-12 col-lg-3 col-md-3">
                                        {
                                            (taskView != null && taskView.user_request_document != null && taskView.user_request_document.length > 0) ?
                                                taskView.user_request_document.map((objSupportLink, index) => (
                                                    <>
                                                        <div>
                                                            {/* <a target="_blank" href={objSupportLink.user_request_document} download="Meeting Document">Document {index + 1}</a> */}
                                                            <a className="primary"
                                                                onClick={() => {
                                                                    if (objSupportLink.user_request_document?.split('.').pop() == 'pdf' ||
                                                                        objSupportLink.user_request_document?.split('.').pop() == 'PDF' ||
                                                                        objSupportLink.user_request_document?.split('.').pop() == 'png' ||
                                                                        objSupportLink.user_request_document?.split('.').pop() == 'jpg' ||
                                                                        objSupportLink.user_request_document?.split('.').pop() == 'jpeg' ||
                                                                        objSupportLink.user_request_document?.split('.').pop() == 'gif'
                                                                    ) {
                                                                        setSelectedDocumentIndex(objSupportLink.user_request_document)
                                                                        setShowDocumentDetails(true);
                                                                        setExtension(objSupportLink.user_request_document?.split('.').pop())
                                                                        setDocumentDetails(objSupportLink)
                                                                    } else {
                                                                        downloadFile(objSupportLink.user_request_document);
                                                                    }
                                                                }}
                                                            >
                                                                Document {index + 1}
                                                            </a>
                                                            <a id="downloadLink" className="d-none"></a>
                                                        </div>
                                                    </>

                                                )) : <>-</>
                                        }
                                    </div>
                                </div>
                                :
                                <></>
                        }
                        {
                            requestHistory != null ?
                                <RequestHistory
                                    history={requestHistory}
                                />
                                :
                                <></>
                        }
                        <div className="form-actions border-0 pt-1 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (props?.location?.state?.type === 'TASK_CREATE' ||
                                        props?.location?.state?.type === 'TASK_TO_REVIEW' ||
                                        props?.location?.state?.type === 'TASK_TO_COMPLETED' ||
                                        props?.location?.state?.type === 'TASK_TO_REOPEN' ||
                                        props?.location?.state?.type === 'TASK_TO_CANCELLED' ||
                                        props?.location?.state?.type === 'TASK_IN_PROGRESS' ||
                                        props?.location?.state?.type === 'SELF_TASK_CREATE'
                                    ) {
                                        props.history.push({
                                            pathname: '/notification'
                                        })
                                    } else {
                                        props.history.goBack();
                                    }
                                }}
                            >Back</button>

                            {
                                showActionButton ?
                                    <button
                                        id="your_action"
                                        className="btn btn-outline-primary mr-1 shadow round text-uppercase mt-1"
                                        onClick={() => {
                                            setShowChangeStatus(true);
                                        }}
                                    >Your Action</button>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
                <TaskStatus
                    task={taskView}
                    show={showChangeStatus}
                    handleClose={() => {
                        setShowChangeStatus(false);
                        setRequestHistory(null);
                        props.actionTaskView({
                            id: props.location.state.id,
                        });
                    }}
                />
                <DocumentIframe
                    {...props}
                    show={showDocumentDetails}
                    documentDetails={selectedDocumentIndex}
                    detailsOfDocument={documentDetails}
                    extension={extension}
                    handleClose={() => {
                        setShowDocumentDetails(false);
                    }}
                />
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var TaskView = null;

    if (
        state.TaskViewReducer != null &&
        Object.keys(state.TaskViewReducer).length > 0 &&
        state.TaskViewReducer.constructor === Object
    ) {
        switch (state.TaskViewReducer.tag) {
            case ACTION_TASK_VIEW_SUCCESS:
                TaskView = Object.assign({}, state.TaskViewReducer.data);
                delete state.TaskViewReducer;
                return { TaskView };
            default:
        }
    }

    return {
        TaskView
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionTaskView }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TasksView));

