import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../component/form/error_label";
import { DropDownListComponent, MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import { DateBox } from "devextreme-react/date-box";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ERROR, UNAUTHORIZED } from "../../../constants";
import dateFormat from "dateformat";
import {
  actionPolicyGroup,
  ACTION_MASTER_POLICY_SUCCESS,
  actionOpportunityQuestionsList,
  ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS,
  actionGetOpportunityQuestions,
  ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS,
} from "./action";
import {
  actionInsuranceCompanyGroup,
  ACTION_MASTER_INSURANCE_COMPANY_SUCCESS,
} from "./action";
import {
  actionProbabilityGroup,
  ACTION_MASTER_PROBABILITY_SUCCESS,
} from "./action";
import {
  actionGetAssignProductRM,
  ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS,
} from "./action";
import { actionGetBrokerName, ACTION_GET_BROKER_NAME_SUCCESS } from "./action"
import { actionTagUserList, ACTION_GET_TAG_USER_SUCCESS } from "./action";
import { actionSelectCustomer, ACTION_CLIENT_NAME_SUCCESS } from "./action";
import { actionMeetingType, ACTION_API_CLIENT_TYPE_SUCCESS } from "./action";
import { random } from "lodash";
import { toast } from "react-toastify";
import moment from "moment";
import { ToWords } from "to-words";
import { CalculateProposalMonth } from "../../../utils";

const PolicyDetailModal = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      client_remark: "",
      department_name: "",
      opportunityQuestion: [],
      product_specialist_id: 0,
    },
  });
  const { fields: opportunityQuestionFields } = useFieldArray({
    control,
    name: "opportunityQuestion",
  });

  const [policyGroup, setPolicyGroup] = useState([]);
  // const [expiringDate, setExpiringDate] = useState(null);
  const [proposalItemId, setProposalItemId] = useState(null);
  const [insuranceCompanyGroup, setInsuranceCompanyGroup] = useState([]);
  const [getAssignProductRM, setGetAssignProductRM] = useState([]);
  const [getAssignRM, setGetAssignRM] = useState(0);
  const [probabilityGroup, setProbabilityGroup] = useState([]);
  const [insurerCompany, setInsurerCompany] = useState("");
  const [policy, setPolicy] = useState("");
  const [department, setDepartment] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  const [isProductSpecialist, setIsProductSpecialist] = useState(1);
  const [probability, setProbability] = useState(null);
  const [arrSystemProbability, setArrSystenProbability] = useState([]);
  const [isOpportunityQuestion, setIsOpportunityQuestion] = useState(false);
  const [opportunityType, setOpportunityType] = useState(null);
  const [intermediaryType, setIntermediaryType] = useState(null);
  const [getBrokerName, setGetBrokerName] = useState([]);
  const [getTagUser, setTagUserList] = useState([]);
  const [originalDate, setOriginalDate] = useState(null);
  const [meetingType, setMeetingType] = useState();
  const [customer, setCustomer] = useState();
  const [meetingTypeId, setMeetingTypeId] = useState(null);
  const [customerPolicyId, setCustomerPolicyId] = useState(0);
  const [customerCompanyId, setCustomerCompanyId] = useState(null);
  const [numberInWords, setNumberInWords] = useState(0);
  const [assignRMId, setAssignRMId] = useState(null);
  const watchExpectedPremium = watch("expected_premium");

  const initialRender = () => {
    $("#save-policy-details").prop("disabled", false);
    if (props.show === true) {
      setAssignRMId(props?.assignRMId != null ? props?.assignRMId : "");
      setProposalItemId(props.data != null ? props.data.id : "");
      props.actionPolicyGroup({ status: "ACTIVE", orderBy: "name" });
      // props.actionInsuranceCompanyGroup({ status: 'ACTIVE', department_id: departmentId });
      props.actionProbabilityGroup();
      props.actionMeetingType({});
      props.actionGetBrokerName();
      props.actionTagUserList();
      $("#root").addClass("modal-open");
      $("#root").append('<div class="modal-backdrop fade show"></div>');
      if (props.data !== null || props.data !== undefined) {
        $("#policy-details-form-loader").addClass("hidden");
        setProbability(props?.data?.probability);
        setValue(
          "broker",
          props.data !== null && props.data.broker != null
            ? props.data.broker
            : ""
        );
        setValue(
          "expected_premium",
          props.data !== null && props.data.expected_premium != null
            ? props.data.expected_premium
            : ""
        );
        setValue(
          "expiring_date",
          props.data !== null &&
            props.data.lead_expiry_date != null &&
            props.data.lead_expiry_date != "0000-00-00 00:00:00"
            ? props.data.lead_expiry_date
            : null
        );
        setValue(
          "probability",
          props.data !== null && props.data.probability != null
            ? props.data.probability
            : ""
        );
        setValue(
          "client_remark",
          props.data !== null && props.data.client_remark != null
            ? props.data.client_remark
            : ""
        );
        setValue(
          "department_name",
          props.data !== null && props.data.department_name != null
            ? props.data.department_name
            : ""
        );
        setValue(
          "is_product_specialist_required",
          props.data !== null &&
            props.data.is_product_specialist_required != null &&
            props.data.is_product_specialist_required === 1
            ? true
            : false
        );
        setValue(
          "product_specialist_id",
          props.data !== null && props.data.product_specialist_id != null
            ? props.data.product_specialist_id
            : 0
        );
        setValue(
          "risk_advisory_activity_done",
          props.data !== null && props.data.risk_advisory_activity_done != null
            ? props.data.risk_advisory_activity_done
            : ""
        );
        setValue(
          "exclusive_mandate_received",
          props.data !== null && props.data.exclusive_mandate_received != null
            ? props.data.exclusive_mandate_received
            : ""
        );
        setValue(
          "product_specialist_involved",
          props.data !== null && props.data.product_specialist_involved != null
            ? props.data.product_specialist_involved
            : ""
        );
        setValue(
          "oppurtunity_type",
          props.data !== null && props.data.oppurtunity_type != null
            ? props.data.oppurtunity_type
            : ""
        );
        setValue(
          "intermediary_type",
          props.data !== null && props.data.intermediary_type != null
            ? props.data.intermediary_type
            : ""
        );
        setValue(
          "intermediary_value",
          props.data !== null && props.data.intermediary_value != null
            ? props.data.intermediary_value
            : ""
        );
        setIntermediaryType(props?.data?.intermediary_type);
        var arrData = [];
        if (
          props.data != null &&
          props.data.opportunity_questions != null &&
          props.data.opportunity_questions != undefined &&
          props.data.opportunity_questions.length > 0
        ) {
          arrSystemProbability.forEach((element) => {
            var value = props.data.opportunity_questions.find(
              (item) => item.id === element.id
            );
            var questionValue = "";
            if (value != null && value != undefined && value != "") {
              questionValue = value.question_value;
              setIsOpportunityQuestion(true);
            } else {
              setIsOpportunityQuestion(false);
            }
            arrData.push({
              id: element.id,
              question: element.question,
              question_value: questionValue,
              yes_value: element.yes_value,
              no_value: element.no_value,
            });
          });
          setValue("opportunityQuestion", arrData);
          setArrSystenProbability(arrData);
        } else {
          props.actionOpportunityQuestionsList({});
        }
      } else {
        reset();
        setValue("probability", "");
        setValue("department_name", "");
        setValue("product_specialist_id", 0);
      }
    } else {
      reset();
      setPolicy("");
      $("#policy-details-form-loader").addClass("hidden");
      $("#root").removeClass("modal-open");
      $(".modal-backdrop").remove();
      setPolicyGroup([]);
      setValue("meeting_type", null);
      setValue("client_id", null);
      setInsuranceCompanyGroup([]);
      setProbabilityGroup([]);
      setGetBrokerName([]);
      setTagUserList([]);
      setValue("broker", "");
      setValue("expected_premium", "");
      setValue("expiring_date", "");
      setValue("policy_type_id", null);
      setValue("oppurtunity_type", null);
      setDepartment(null);
      setDepartmentId(null);
      setProbability(null);
      setOpportunityType(null);
    }
  };
  useEffect(initialRender, [props.show]);

  useEffect(() => {
    if (policyGroup != null && policyGroup.length > 0) {
      if (props.data !== null) {
        setValue(
          "policy_type_id",
          props.data != null ? props.data.policy_type_id : ""
        );
      }
    }
  }, [policyGroup]);

  useEffect(() => {
    if (watchExpectedPremium != null) {
      const isNumeric = /^[0-9]*$/.test(watchExpectedPremium);
      if (isNumeric) {
        const numericValue = parseFloat(watchExpectedPremium || "0");
        const toWords = new ToWords();
        const words = toWords.convert(numericValue);
        setNumberInWords(words);
      }
    } else {
      setNumberInWords("");
    }
  }, [watchExpectedPremium]);

  const onMasterPolicyGroupUpdate = () => {
    const { PolicyGroup } = props;
    var PolicyGroupResponse = Object.assign({}, PolicyGroup);
    if (PolicyGroupResponse.result === true) {
      setPolicyGroup(PolicyGroupResponse.response);
    } else {
      switch (PolicyGroupResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(PolicyGroupResponse.message);
        default:
      }
    }
  };
  useEffect(onMasterPolicyGroupUpdate, [props.PolicyGroup]);

  useEffect(() => {
    if (departmentId != null) {
      props.actionGetAssignProductRM({
        department_id: departmentId,
        assigned_rm_id: assignRMId,
      });
      props.actionInsuranceCompanyGroup({
        status: "ACTIVE",
        department_id: departmentId,
      });
    }
  }, [departmentId]);

  useEffect(() => {
    if (getAssignProductRM != null && getAssignProductRM.length > 0) {
      if (props.data != null) {
        if (props?.data?.policy_type_id != getValues("policy_type_id")) {
          setValue("product_specialist_id", 0);
        } else {
          setValue("product_specialist_id", props?.data?.product_specialist_id);
        }
      }
    }
  }, [getAssignProductRM]);

  const onAssignProductRMUpdate = () => {
    const { GetAssignProductRM } = props;
    var GetAssignProductRMResponse = Object.assign({}, GetAssignProductRM);
    if (GetAssignProductRMResponse.result === true) {
      var arrAssignRM = Object.assign([], GetAssignProductRMResponse.response);

      var user = {
        user_id: 0,
        full_name: "None",
      };

      arrAssignRM.unshift(user);

      setGetAssignProductRM(arrAssignRM);
    } else {
      switch (GetAssignProductRMResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          setGetAssignProductRM([{ user_id: 0, full_name: "None" }]);
        // toast.error(GetAssignProductRMResponse.message);
        default:
      }
    }
  };
  useEffect(onAssignProductRMUpdate, [props.GetAssignProductRM]);

  const onMasterInsuranceCompanyGroupUpdate = () => {
    const { InsuranceCompanyGroup } = props;
    var InsuranceCompanyGroupResponse = Object.assign(
      {},
      InsuranceCompanyGroup
    );
    if (InsuranceCompanyGroupResponse.result === true) {
      setInsuranceCompanyGroup(InsuranceCompanyGroupResponse.response);
      setValue("insurer_id", props.data != null ? props.data.insurer_id : "");
    } else {
      switch (InsuranceCompanyGroupResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(InsuranceCompanyGroupResponse.message);
        default:
      }
    }
  };
  useEffect(onMasterInsuranceCompanyGroupUpdate, [props.InsuranceCompanyGroup]);

  useEffect(() => {
    if (getTagUser != null && getTagUser.length > 0) {
      if (props.data !== null) {
        setValue(
          "tag_user_id",
          props.data !== null ? props?.data?.tag_user_id : ""
        );
      }
    }
  }, [getTagUser]);

  useEffect(() => {
    if (probabilityGroup != null && probabilityGroup.length > 0) {
      if (props.data !== null) {
        setValue(
          "probability",
          props.data.probability != null ? Number(props.data.probability) : ""
        );
      }
    }
  }, [probabilityGroup]);

  const onMasterProbabilityGroupUpdate = () => {
    const { ProbabilityGroup } = props;
    var ProbabilityGroupResponse = Object.assign({}, ProbabilityGroup);
    if (ProbabilityGroupResponse.result === true) {
      setProbabilityGroup(ProbabilityGroupResponse.response);
    } else {
      switch (ProbabilityGroupResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(ProbabilityGroupResponse.message);
        default:
      }
    }
  };
  useEffect(onMasterProbabilityGroupUpdate, [props.ProbabilityGroup]);

  const onMasterTagUserUpdate = () => {
    const { TagUserList } = props;
    var TagUserResponse = Object.assign({}, TagUserList);
    if (TagUserResponse.result === true) {
      console.log(TagUserResponse, "sgdhgsds")
      setTagUserList(TagUserResponse.response);
    } else {
      switch (TagUserResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(TagUserResponse.message);
        default:
      }
    }
  };
  useEffect(onMasterTagUserUpdate, [props.TagUserList]);

  const onOpportunityQuestionListUpdate = () => {
    const { OpportunityQuestionList } = props;
    var OpportunityQuestionListResponse = Object.assign(
      {},
      OpportunityQuestionList
    );
    if (OpportunityQuestionListResponse.result === true) {
      var opportunityQuestionArr = OpportunityQuestionListResponse.response.map(
        (item) => ({
          ...item,
          question_value: "",
        })
      );
      setValue("opportunityQuestion", opportunityQuestionArr);
      setArrSystenProbability(opportunityQuestionArr);
      if (
        props?.data?.id != null &&
        props?.data?.id != "" &&
        props?.data?.probability >= 50
      ) {
        props.actionGetOpportunityQuestions({ id: props.data.id });
      }
    } else {
      switch (OpportunityQuestionListResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onOpportunityQuestionListUpdate, [props.OpportunityQuestionList]);

  const onGetOpportunityQuestionUpdate = () => {
    const { GetOpportunityQuestion } = props;
    var GetOpportunityQuestionResponse = Object.assign(
      {},
      GetOpportunityQuestion
    );
    if (GetOpportunityQuestionResponse.result === true) {
      var arrData = [];
      if (
        GetOpportunityQuestionResponse.response.opportunity_questions != null &&
        GetOpportunityQuestionResponse.response.opportunity_questions.length >
        0 &&
        arrSystemProbability != null &&
        arrSystemProbability.length > 0
      ) {
        arrSystemProbability.forEach((element) => {
          var value =
            GetOpportunityQuestionResponse.response.opportunity_questions.find(
              (item) => item.id === element.id
            );
          var questionValue = "";
          if (value != null && value != undefined) {
            questionValue = value.question_value;
            setIsOpportunityQuestion(false);
          } else {
            setIsOpportunityQuestion(true);
          }

          arrData.push({
            id: element.id,
            question: element.question,
            question_value: questionValue,
          });
        });
        setValue("opportunityQuestion", arrData);
        setArrSystenProbability(arrData);
      }
    } else {
      switch (GetOpportunityQuestionResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onGetOpportunityQuestionUpdate, [props.GetOpportunityQuestion]);

  useEffect(() => {
    if (getBrokerName != null && getBrokerName.length > 0) {
      if (props.data !== null) {
        setValue(
          "broker_id",
          props.data !== null ? props?.data?.intermediary_value : ""
        );
      }
    }
  }, [getBrokerName]);



  const onGetBrokerNameUpdate = () => {
    const { GetBrokerName } = props;
    var GetBrokerNameResponse = Object.assign({}, GetBrokerName);
    if (GetBrokerNameResponse.result === true) {
      setGetBrokerName(GetBrokerNameResponse.response);
    } else {
      switch (GetBrokerNameResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(GetBrokerNameResponse.message);
        default:
      }
    }
  };
  useEffect(onGetBrokerNameUpdate, [props.GetBrokerName]);

  const onMeetingTypeUpdate = () => {
    const { MeetingType } = props;
    var MeetingTypeResponse = Object.assign({}, MeetingType);
    if (MeetingTypeResponse.result === true) {
      setMeetingType(MeetingTypeResponse.response);
    } else {
      switch (MeetingTypeResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onMeetingTypeUpdate, [props.MeetingType]);

  // Call On Change of Meeting With To Get The Customer List **Select Customer**
  const onSelectCustomerUpdate = () => {
    const { Customers } = props;
    var CustomersResponse = Object.assign({}, Customers);
    if (CustomersResponse.result === true) {
      setCustomer(CustomersResponse.response);
    } else {
      switch (CustomersResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onSelectCustomerUpdate, [props.Customers]);

  const onSubmit = (data) => {
    var arrData = [];
    data.opportunityQuestion.forEach((element) => {
      if (element.question_value === undefined) {
        var value = arrSystemProbability.find((item) => item.id === element.id);
        var questionValue = "";
        if (value != null && value != undefined) {
          //questionValue = value.question_value
          arrData.push({
            id: element.id,
            question: element.question,
            question_value: value.question_value,
            yes_value: element.yes_value,
            no_value: element.no_value,
          });
        } else {
          arrData.push({
            id: element.id,
            question: element.question,
            question_value: element.question_value,
            yes_value: element.yes_value,
            no_value: element.no_value,
          });
        }
      } else {
        arrData.push({
          id: element.id,
          question: element.question,
          question_value: element.question_value,
          yes_value: element.yes_value,
          no_value: element.no_value,
        });
      }
    });

    var newPolicy = {};
    if (props.data != null && props.data.hasOwnProperty("id")) {
      newPolicy.id = props.data.id;
    }

    if (props.opportunityPool === true) {
      newPolicy.client_id = data.client_id;
    }
    newPolicy.department_id = departmentId;
    newPolicy.department_name = department;
    newPolicy.policy_type_id = data.policy_type_id;
    newPolicy.policy_type = policy;
    newPolicy.tag_user_id = data.tag_user_id
    newPolicy.product_specialist_id = data.product_specialist_id;
    newPolicy.expected_premium = data.expected_premium;
    newPolicy.probability = data.probability;
    newPolicy.status = "open";
    newPolicy.client_remark = data.client_remark;
    newPolicy.is_product_specialist_required = isProductSpecialist;
    newPolicy.risk_advisory_activity_done = data.risk_advisory_activity_done;
    newPolicy.exclusive_mandate_received = data.exclusive_mandate_received;
    newPolicy.product_specialist_involved = data.product_specialist_involved;
    newPolicy.opportunity_questions =
      arrData != null && arrData.length > 0
        ? arrData
        : data.opportunityQuestion != null
          ? data.opportunityQuestion
          : arrSystemProbability;
    newPolicy.oppurtunity_type = data.oppurtunity_type;
    newPolicy.insurer_name = insurerCompany;
    newPolicy.insurer_id = data.insurer_id;
    newPolicy.expiring_date =
      data.expiring_date != null ? data.expiring_date : null;
    newPolicy.lead_expiry_date =
      data.expiring_date != null ? data.expiring_date : null;
    newPolicy.intermediary_type = data.intermediary_type;
    if (data.oppurtunity_type === "Fresh") {
      delete data.intermediary_type;
      delete data.intermediary_value;
    } else {
      if (data.intermediary_type === "Broker") {
        newPolicy.intermediary_value = data.broker_id;
      } else {
        newPolicy.intermediary_value = data.intermediary_value;
      }
    }
    $("#policy-details-form-loader").removeClass("hidden");
    $("#save-policy-details").prop("disabled", true);
    props.handleClose(newPolicy);
    setValue("department_name", "");
    console.log(data, "my data")
  };

  return (
    <div
      className="modal fade show"
      id="default"
      role="dialog"
      aria-labelledby="myModalLabel1"
      aria-hidden="true"
      style={{ display: `${props.show === true ? "block" : "none"}` }}
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="media-middle mt-1">
              <h4 className="modal-title" id="myModalLabel1">
                {proposalItemId ? "Edit" : "Add "} Opportunity Details
              </h4>
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                props.handleClose();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ overflow: "visible" }}>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                <form
                  noValidate
                  autoComplete="Off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-body">
                    <div className="row">
                      {props.opportunityPool === true ? (
                        <>
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Client Type&nbsp;
                                <span className="danger">*</span>
                              </label>
                              <Controller
                                name="meeting_type"
                                className="form-control"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <DropDownListComponent
                                    {...field}
                                    dataSource={meetingType}
                                    fields={{ text: "title", value: "value" }}
                                    allowFiltering={false}
                                    placeholder="Select Client Type"
                                    value={field.value}
                                    change={(event) => {
                                      if (event.itemData != null) {
                                        // Reset Customer
                                        setCustomerCompanyId(null);
                                        setCustomerPolicyId(0);
                                        setCustomer([]);
                                        // EOC : Reset Customer

                                        field.onChange(event.itemData.value);
                                        setMeetingTypeId(event.itemData.value);

                                        if (event.itemData.value === "lead") {
                                          props.actionSelectCustomer({
                                            client_type: "lead",
                                          });
                                        } else {
                                          props.actionSelectCustomer({
                                            client_type: "client",
                                          });
                                        }
                                      }
                                    }}
                                  />
                                )}
                                rules={{
                                  required: "Client Type is required",
                                }}
                              />
                              {errors.meeting_type && (
                                <ErrorLabel
                                  message={errors.meeting_type?.message}
                                />
                              )}
                            </div>
                          </div>
                          <div
                            className="col-12 col-md-4 col-lg-4"
                            id="customer-field"
                          >
                            <div className="form-group">
                              <label className="label-control">
                                Client Name&nbsp;
                                <span className="danger">*</span>
                              </label>
                              <Controller
                                name="client_id"
                                className="form-control"
                                control={control}
                                render={({ field }) => (
                                  <DropDownListComponent
                                    {...field}
                                    dataSource={customer}
                                    fields={{ text: "name", value: "id" }}
                                    allowFiltering={true}
                                    filterType={"Contains"}
                                    placeholder="Select Customer"
                                    value={field.value}
                                    change={(event) => {
                                      if (event.itemData != null) {
                                        field.onChange(event.itemData.id);
                                        setCustomerCompanyId(event.itemData.id);
                                        // AssignRM API
                                        setAssignRMId(
                                          event.itemData.created_by
                                        );
                                        // props.actionGetAssignProductRM({ department_id: departmentId, assigned_rm_id: event.itemData.created_by })
                                        // setCustomerPolicyId(event.itemData.id)
                                        // props.actionPreviousMeeting({ client_id: event.itemData.id });
                                        // props.actionCustomerMeetingContact({ client_id: event.itemData.id })
                                        // props.actionCustomerMeetingPolicy({ client_id: event.itemData.id })
                                      }
                                    }}
                                  />
                                )}
                                rules={{
                                  required: "Customer is required",
                                }}
                              />
                              {errors.client_id && (
                                <ErrorLabel
                                  message={errors.client_id?.message}
                                />
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {props?.data?.oppurtunity_type === "Renewal" ? (
                        <>
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Control Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="control_no"
                                name="control_no"
                                value={
                                  props?.data != null
                                    ? props.data?.control_no
                                    : ""
                                }
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Policy Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="policy_no"
                                name="policy_no"
                                value={
                                  props?.data != null
                                    ? props.data?.policy_no
                                    : ""
                                }
                                disabled={true}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">
                            Opportunity Type&nbsp;
                            <span className="danger">*</span>
                          </label>
                          <Controller
                            name="oppurtunity_type"
                            control={control}
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                dataSource={[
                                  {
                                    id: "Fresh",
                                    name: "Fresh",
                                  },
                                  {
                                    id: "Market Roll Over",
                                    name: "Market Roll Over",
                                  },
                                ]}
                                id="oppurtunity_type"
                                name="oppurtunity_type"
                                fields={{ text: "name", value: "id" }}
                                allowFiltering={false}
                                value={field.value}
                                placeholder="Select Opportunity Type"
                                change={(event) => {
                                  if (event.itemData != null) {
                                    field.onChange(event.itemData.id);
                                    setOpportunityType(event.itemData.id);
                                  }
                                }}
                              />
                            )}
                            rules={{
                              required: "Please Select Opportunity Type",
                            }}
                          />
                          {errors.oppurtunity_type && (
                            <ErrorLabel
                              message={errors.oppurtunity_type?.message}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">
                            {" "}
                            Policy Type&nbsp;<span className="danger">*</span>
                          </label>
                          <Controller
                            name="policy_type_id"
                            control={control}
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                dataSource={policyGroup}
                                id="policy_type_id"
                                name="policy_type_id"
                                fields={{ text: "name", value: "id" }}
                                allowFiltering={true}
                                filterType={"Contains"}
                                value={field.value}
                                placeholder="Select Policy Type"
                                change={(event) => {
                                  if (event.itemData != null) {
                                    // Set Policy Name So That We Can Use That Name To Show In Front Page
                                    setPolicy(event.itemData.name);
                                    setDepartment(
                                      event.itemData.department_name
                                    );
                                    setDepartmentId(
                                      event.itemData.department_id
                                    );
                                    field.onChange(event.itemData.id);
                                    setValue("product_specialist_id", 0);
                                  }
                                }}
                              />
                            )}
                            rules={{
                              required: "Please Select Policy",
                            }}
                          />
                          {errors.policy_type_id && (
                            <ErrorLabel
                              message={errors.policy_type_id?.message}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">Department</label>
                          <input
                            type="text"
                            className="form-control"
                            id="department_name"
                            name="department_name"
                            value={department != null ? department : ""}
                            disabled={true}
                            placeholder="Enter Department"
                          />
                        </div>
                      </div>
                      {opportunityType != null &&
                        opportunityType === "Market Roll Over" ? (
                        <>
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Intermediary Type
                              </label>
                              <Controller
                                name="intermediary_type"
                                control={control}
                                render={({ field }) => (
                                  <DropDownListComponent
                                    {...field}
                                    dataSource={[
                                      {
                                        name: "Agent",
                                      },
                                      {
                                        name: "Broker",
                                      },
                                      {
                                        name: "Direct",
                                      },
                                      {
                                        name: "Banca",
                                      },
                                      {
                                        name: "Others",
                                      },
                                      {
                                        name: "No Information",
                                      },
                                    ]}
                                    id="intermediary_type"
                                    name="intermediary_type"
                                    fields={{ text: "name", value: "name" }}
                                    allowFiltering={true}
                                    filterType={"Contains"}
                                    value={field.value}
                                    placeholder="Select Intermediary Type"
                                    change={(event) => {
                                      if (event.itemData != null) {
                                        field.onChange(event.itemData.name);
                                        setIntermediaryType(
                                          event.itemData.name
                                        );
                                        setValue("intermediary_value", "");
                                      }
                                    }}
                                  />
                                )}
                              />
                              {errors.intermediary_type && (
                                <ErrorLabel
                                  message={errors.intermediary_type?.message}
                                />
                              )}
                            </div>
                          </div>
                          {intermediaryType != null &&
                            intermediaryType != "Broker" ? (
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control">
                                  Intermediary Details
                                </label>
                                <input
                                  className="form-control"
                                  id="intermediary_value"
                                  name="intermediary_value"
                                  placeholder="Enter Intermediary Details"
                                  {...register("intermediary_value")}
                                />
                                {errors.intermediary_value && (
                                  <ErrorLabel
                                    message={errors.intermediary_value?.message}
                                  />
                                )}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {intermediaryType != null &&
                            intermediaryType === "Broker" ? (
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control">
                                  Broker's name
                                </label>
                                <Controller
                                  name="broker_id"
                                  control={control}
                                  render={({ field }) => (
                                    <DropDownListComponent
                                      {...field}
                                      dataSource={getBrokerName}
                                      id="broker_id"
                                      name="broker_id"
                                      fields={{ text: "name", value: "name" }}
                                      allowFiltering={true}
                                      filterType={"Contains"}
                                      value={field.value}
                                      placeholder="Select Broker's name"
                                      change={(event) => {
                                        if (event.itemData != null) {
                                          field.onChange(event.itemData.name);
                                        }
                                      }}
                                    />
                                  )}
                                />
                                {errors.broker_id && (
                                  <ErrorLabel
                                    message={errors.broker_id?.message}
                                  />
                                )}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      <div
                        className={`col-12 col-md-4 col-lg-4 ${opportunityType != "Fresh" ? "" : "hidden"
                          }`}
                      >
                        <div className="form-group">
                          <label className="label-control">
                            Insurance Company
                          </label>
                          <Controller
                            name="insurer_id"
                            control={control}
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                dataSource={insuranceCompanyGroup}
                                id="insurer_id"
                                name="insurer_id"
                                fields={{ text: "name", value: "id" }}
                                allowFiltering={true}
                                filterType={"Contains"}
                                placeholder="Select Insurance Company"
                                value={field.value}
                                change={(event) => {
                                  if (event.itemData != null) {
                                    setInsurerCompany(event.itemData.name);
                                    field.onChange(event.itemData.id);
                                  }
                                }}
                              />
                            )}
                          />
                          {errors.insurer_id && (
                            <ErrorLabel message={errors.insurer_id?.message} />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">
                            Expected Premium&nbsp;
                            <span
                              className={`danger ${opportunityType === "Market Roll Over"
                                  ? ""
                                  : "hidden"
                                }`}
                            >
                              *
                            </span>
                          </label>
                          <input
                            min={0}
                            className="form-control"
                            id="expected_premium"
                            name="expected_premium"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onPaste={(event) => {
                              const pastedText =
                                event.clipboardData.getData("text/plain");
                              const textWithoutCommas = pastedText.replace(
                                /,/g,
                                ""
                              );
                              if (!/^\d+$/.test(textWithoutCommas)) {
                                event.preventDefault();
                              } else {
                                document.execCommand(
                                  "insertText",
                                  false,
                                  textWithoutCommas
                                );
                                event.preventDefault();
                              }
                            }}
                            placeholder="Enter Expected Premium"
                            {...register("expected_premium", {
                              required:
                                opportunityType === "Market Roll Over"
                                  ? "Expected Premium is required"
                                  : "",
                            })}
                          />
                          <div className="font-small-2 text-success mt-half">
                            {numberInWords != "Zero" ? numberInWords : ""}
                          </div>
                          {errors.expected_premium && (
                            <ErrorLabel
                              message={errors.expected_premium?.message}
                            />
                          )}
                        </div>
                      </div>
                      {opportunityType != null && opportunityType != "Fresh" ? (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="label-control">
                              Renewal Date &nbsp;
                              <span
                                className={`danger ${opportunityType === "Market Roll Over"
                                    ? ""
                                    : "hidden"
                                  }`}
                              >
                                *
                              </span>
                            </label>

                            <Controller
                              name="expiring_date"
                              control={control}
                              render={({ field }) => (
                                <DateBox
                                  calendarOptions={{
                                    showTodayButton: true,
                                  }}
                                  {...field}
                                  id="expiring_date"
                                  name="expiring_date"
                                  min={moment(new Date())
                                    .subtract(2, "M")
                                    .format("YYYY-MM-DD")}
                                  stylingMode="underlined"
                                  className="form-control"
                                  pickerType="calendar"
                                  type="date"
                                  value={field.value}
                                  acceptCustomValue={false}
                                  displayFormat="dd/MM/yyyy"
                                  showClearButton={true}
                                  onValueChange={(value) => {
                                    field.onChange(
                                      dateFormat(value, "yyyy-mm-dd")
                                    );
                                    // setExpiringDate(
                                    //   dateFormat(value, "yyyy-mm-dd")
                                    // );
                                  }}
                                />
                              )}
                              rules={{
                                required:
                                  opportunityType === "Market Roll Over"
                                    ? "Renewal Date is required"
                                    : "",
                              }}
                            />
                            {errors.expiring_date && (
                              <ErrorLabel
                                message={errors.expiring_date?.message}
                              />
                            )}
                          </div>
                        </div>
                      ) : opportunityType != null &&
                        opportunityType === "Fresh" ? (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="label-control">
                              Expected Closure Date&nbsp;
                              <span className="danger">*</span>
                            </label>
                            <Controller
                              name="expiring_date"
                              control={control}
                              render={({ field }) => (
                                <DateBox
                                  calendarOptions={{
                                    showTodayButton: true,
                                  }}
                                  {...field}
                                  id="expiring_date"
                                  name="expiring_date"
                                  stylingMode="underlined"
                                  min={moment()
                                    .startOf("month")
                                    .format("YYYY-MM-DD")}
                                  className="form-control"
                                  pickerType="calendar"
                                  type="date"
                                  value={field.value}
                                  acceptCustomValue={false}
                                  displayFormat="dd/MM/yyyy"
                                  showClearButton={true}
                                  onValueChange={(value) => {
                                    field.onChange(
                                      dateFormat(value, "yyyy-mm-dd")
                                    );
                                    // setExpiringDate(
                                    //   dateFormat(value, "yyyy-mm-dd")
                                    // );
                                  }}
                                />
                              )}
                              rules={{
                                required:
                                  opportunityType === "Fresh"
                                    ? "Expected Closure Date is required"
                                    : "",
                              }}
                            />
                            {errors.expiring_date && (
                              <ErrorLabel
                                message={errors.expiring_date?.message}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* {expiringDate && (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="label-control">
                              Proposal Month
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              value={CalculateProposalMonth(
                                getValues("expiring_date")
                              )}
                            />
                          </div>
                        </div>
                      )} */}
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">
                            RM Probability (in %)
                          </label>
                          <Controller
                            name="probability"
                            key={random(5425245)}
                            control={control}
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                dataSource={probabilityGroup}
                                id="probability"
                                name="probability"
                                fields={{ text: "title", value: "value" }}
                                allowFiltering={false}
                                placeholder="Select Probability"
                                value={field.value}
                                change={(event) => {
                                  if (event.itemData != null) {
                                    field.onChange(event.itemData.value);
                                    setProbability(event.itemData.value);
                                  }
                                }}
                              />
                            )}
                          />
                          {errors.probability && (
                            <ErrorLabel message={errors.probability?.message} />
                          )}
                        </div>
                      </div>
                      {probability != null &&
                        Number(probability) >= 50 &&
                        opportunityQuestionFields != null &&
                        opportunityQuestionFields.length > 0 ? (
                        opportunityQuestionFields.map((item, index) => (
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                {item.question}
                                <span
                                  className={`danger ${isOpportunityQuestion != true
                                      ? ""
                                      : "hidden"
                                    }`}
                                >
                                  *
                                </span>
                              </label>
                              <Controller
                                name={`opportunityQuestion[${index}].question_value`}
                                id={`opportunityQuestion[${index}].question_value`}
                                control={control}
                                render={({ field }) => (
                                  <DropDownListComponent
                                    {...field}
                                    dataSource={[
                                      {
                                        title: "Yes",
                                        value: "Yes",
                                      },
                                      {
                                        title: "No",
                                        value: "No",
                                      },
                                    ]}
                                    fields={{ text: "title", value: "value" }}
                                    value={item.question_value}
                                    placeholder="Select"
                                    change={(event) => {
                                      if (event.itemData != null) {
                                        field.onChange(event.itemData.value);
                                      }
                                    }}
                                  />
                                )}
                                rules={{
                                  required:
                                    isOpportunityQuestion != true
                                      ? true
                                      : false,
                                }}
                              />
                              {errors.opportunityQuestion?.[index]
                                ?.question_value && (
                                  <ErrorLabel message={"Please Select details"} />
                                )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                      <div className="col-12 col-md-4 col-lg-4 pt-1 mb-0 pb-0">
                        <div className="form-group">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              name="is_product_specialist_required"
                              id="is_product_specialist_required"
                              disabled={true}
                              // disabled={props.opportunityPool === true ? true : false}
                              defaultChecked={
                                isProductSpecialist === 1 ? true : false
                              }
                              checked={isProductSpecialist === 1 ? true : false}
                              {...register("is_product_specialist_required")}
                              onChange={(event) => {
                                if (event.target.checked === true) {
                                  setIsProductSpecialist(1);
                                } else {
                                  setIsProductSpecialist(0);
                                }
                              }}
                            />
                            <label
                              class="custom-control-label"
                              for="is_product_specialist_required"
                            >
                              Is Product Specialist Required?
                            </label>
                          </div>
                        </div>
                      </div>
                      {policy != null &&
                        policy != "" &&
                        isProductSpecialist != null &&
                        isProductSpecialist === 1 ? (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="label-control">
                              Assign Product RM
                            </label>
                            <Controller
                              name="product_specialist_id"
                              control={control}
                              render={({ field }) => (
                                <DropDownListComponent
                                  {...field}
                                  dataSource={getAssignProductRM}
                                  id="product_specialist_id"
                                  name="product_specialist_id"
                                  fields={{
                                    text: "full_name",
                                    value: "user_id",
                                  }}
                                  allowFiltering={true}
                                  filterType={"Contains"}
                                  popupHeight={"200px"}
                                  placeholder="Assign Product RM"
                                  value={field.value}
                                  change={(event) => {
                                    if (event.itemData != null) {
                                      setGetAssignRM(event.itemData.title);
                                      field.onChange(event.itemData.user_id);
                                    }
                                  }}
                                />
                              )}
                              rules={{
                                required: "Please select assign product RM",
                              }}
                            />
                            {errors.product_specialist_id && (
                              <ErrorLabel
                                message={errors.product_specialist_id?.message}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">Tag Team Member</label>
                          <Controller
                            name="tag_user_id"
                            control={control}
                            render={({ field }) => (
                              <MultiSelectComponent
                                {...field}
                                dataSource={getTagUser}
                                id="tag_user_id"
                                name="tag_user_id"
                                fields={{ text: "full_name", value: "user_id" }}
                                allowFiltering={true}
                                filterType={"Contains"}
                                value={field.value}
                                placeholder="Select Tag User"
                                change={(event) => {
                                  field.onChange(event.value); // Update the field with the selected values
                                }}
                              />
                            )}
                          />
                          {errors.tag_user_id && (
                            <ErrorLabel message={errors.tag_user_id?.message} />
                          )}
                        </div>
                      </div>


                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">Remark</label>
                          <textarea
                            className="form-control"
                            id="client_remark"
                            name="client_remark"
                            placeholder="Enter Remark"
                            maxLength={250}
                            {...register("client_remark")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-actions border-0 mt-0 pt-0 float-none">
                    <button
                      id="save-policy-details"
                      className="btn btn-primary mr-1 shadow round text-uppercase"
                      type="submit"
                    >
                      <div className="d-flex align-items-center">
                        Submit
                        <span
                          id="policy-details-form-loader"
                          className="spinner-border spinner-border-sm ml-half hidden"
                        ></span>
                      </div>
                    </button>
                    <button
                      className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                      onClick={(event) => {
                        event.preventDefault();
                        props.handleClose();
                        setValue("department_name", "");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  var PolicyGroup = null;
  var InsuranceCompanyGroup = null;
  var ProbabilityGroup = null;
  var OpportunityQuestionList = null;
  var GetOpportunityQuestion = null;
  var GetAssignProductRM = null;
  var GetBrokerName = null;
  var TagUserList = null;
  var MeetingType = null;
  var Customers = null;

  if (
    state.LeadCreatePolicyDetailsReducer != null &&
    Object.keys(state.LeadCreatePolicyDetailsReducer).length > 0 &&
    state.LeadCreatePolicyDetailsReducer.constructor === Object
  ) {
    switch (state.LeadCreatePolicyDetailsReducer.tag) {
      case ACTION_MASTER_POLICY_SUCCESS:
        PolicyGroup = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { PolicyGroup };
      case ACTION_MASTER_INSURANCE_COMPANY_SUCCESS:
        InsuranceCompanyGroup = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { InsuranceCompanyGroup };
      case ACTION_MASTER_PROBABILITY_SUCCESS:
        ProbabilityGroup = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { ProbabilityGroup };
      case ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS:
        OpportunityQuestionList = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { OpportunityQuestionList };
      case ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS:
        GetOpportunityQuestion = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { GetOpportunityQuestion };
      case ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS:
        GetAssignProductRM = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { GetAssignProductRM };
      case ACTION_GET_BROKER_NAME_SUCCESS:
        GetBrokerName = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { GetBrokerName };
      case ACTION_GET_TAG_USER_SUCCESS:
        TagUserList = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { TagUserList };
      case ACTION_API_CLIENT_TYPE_SUCCESS:
        MeetingType = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { MeetingType };
      case ACTION_CLIENT_NAME_SUCCESS:
        Customers = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { Customers };
      default:
    }
  }
  return {
    PolicyGroup,
    InsuranceCompanyGroup,
    ProbabilityGroup,
    OpportunityQuestionList,
    GetOpportunityQuestion,
    GetAssignProductRM,
    GetBrokerName,
    TagUserList,
    MeetingType,
    Customers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      actionPolicyGroup,
      actionInsuranceCompanyGroup,
      actionProbabilityGroup,
      actionOpportunityQuestionsList,
      actionGetOpportunityQuestions,
      actionGetAssignProductRM,
      actionGetBrokerName,
      actionTagUserList,
      actionSelectCustomer,
      actionMeetingType,
    },
    dispatch
  );
};
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(PolicyDetailModal)
);
