import createReducer from "../../../../../reducers/createReducer";
import {
    ACTION_PROPOSALPOOL_EDIT_SUCCESS,
    ACTION_OPPORTUNITY_STATUS_SUCCESS,
    ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_SUCCESS,
    ACTION_OPPORTUNITY_LOST_STATUS_SUCCESS,
    ACTION_OPPORTUNITY_PLACEMENTS_GET_SUCCESS,
    ACTION_MASTER_INSURANCE_OPPORTUNITY_SUCCESS,
    ACTION_MASTER_OPPORTUNITY_POLICY_SUCCESS,
    ACTION_INTERMEDIARY_TYPE_SUCCESS,
    ACTION_OPPORTUNITY_BROKER_NAME_SUCCESS,
    ACTION_MASTER_PROBABILITY_SUCCESS,
    ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS,
    ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS,
    ACTION_GET_CLOSURE_TYPE_SUCCESS,
    ACTION_PROPOSALPOOL_VIEW_SUCCESS,
    ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS,
    ACTION_DEPARTMENT_WISE_GET_INSURANCE_OPPORTUNITY_SUCCESS,
    ACTION_DELETE_DOCUMENT_SUCCESS,
    ACTION_INSURANCE_SUCCESS_LIST_SUCCESS,
    ACTION_INSTALLMENT_SUCCESS,
    ACTION_GET_TAG_USER_SUCCESS
} from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const ProposalPoolEditReducer = createReducer(INITIAL_STATE, {
    [ACTION_PROPOSALPOOL_EDIT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_PROPOSALPOOL_EDIT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_OPPORTUNITY_STATUS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_OPPORTUNITY_STATUS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_OPPORTUNITY_LOST_STATUS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_OPPORTUNITY_LOST_STATUS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_OPPORTUNITY_PLACEMENTS_GET_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_OPPORTUNITY_PLACEMENTS_GET_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_INSURANCE_OPPORTUNITY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_INSURANCE_OPPORTUNITY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_DEPARTMENT_WISE_GET_INSURANCE_OPPORTUNITY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_DEPARTMENT_WISE_GET_INSURANCE_OPPORTUNITY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_OPPORTUNITY_POLICY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_OPPORTUNITY_POLICY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_INTERMEDIARY_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_INTERMEDIARY_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_OPPORTUNITY_BROKER_NAME_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_OPPORTUNITY_BROKER_NAME_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_PROBABILITY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_PROBABILITY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_CLOSURE_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_CLOSURE_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_PROPOSALPOOL_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_PROPOSALPOOL_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_DELETE_DOCUMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_DELETE_DOCUMENT_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_INSURANCE_SUCCESS_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_INSURANCE_SUCCESS_LIST_SUCCESS,
                loading: false,
            }
        );
    },

    [ACTION_GET_TAG_USER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_TAG_USER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_INSTALLMENT_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_INSTALLMENT_SUCCESS,
                loading: false,
            }
        );
    },
}
)
export default ProposalPoolEditReducer