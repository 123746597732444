import createReducer from "../../../reducers/createReducer";
import { ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS, ACTION_MASTER_POLICY_SUCCESS, ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS } from "./action";
import { ACTION_MASTER_INSURANCE_COMPANY_SUCCESS, ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS, ACTION_CLIENT_NAME_SUCCESS } from "./action";
import { ACTION_MASTER_PROBABILITY_SUCCESS, ACTION_GET_BROKER_NAME_SUCCESS, ACTION_API_CLIENT_TYPE_SUCCESS, ACTION_GET_TAG_USER_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const LeadCreatePolicyDetailsReducer = createReducer(INITIAL_STATE, {
    [ACTION_MASTER_POLICY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_POLICY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_INSURANCE_COMPANY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_INSURANCE_COMPANY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_MASTER_PROBABILITY_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_MASTER_PROBABILITY_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_GET_BROKER_NAME_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_BROKER_NAME_SUCCESS,
                loading: false,
            }
        );
    },

    [ACTION_GET_TAG_USER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_GET_TAG_USER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_API_CLIENT_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_API_CLIENT_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_CLIENT_NAME_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_CLIENT_NAME_SUCCESS,
                loading: false,
            }
        );
    },
});
export default LeadCreatePolicyDetailsReducer;    