import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../component/form/error_label"
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { connect } from 'react-redux';
import { withAuth } from 'react-auth-guard';
import { UNAUTHORIZED, ERROR } from '../../../constants';
import { toast } from "react-toastify";
import { bindActionCreators } from 'redux';
import {
    actionProposalQuoteRejectStatus,
    ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_SUCCESS,
} from "./action";
import Swal from 'sweetalert2';
const PoolBulkAction = (props) => {

    const { register, handleSubmit, formState: { errors }, reset, control } = useForm();

    const [quoteRejectStatusDropdown, QuoteRejectStatusDropdown] = useState([]);

    const initialRender = () => {

        if (props.show === true) {
            props.actionProposalQuoteRejectStatus();
            QuoteRejectStatusDropdown([]);         
            $("#root").addClass("modal-open");
            $("#root").append('<div class="modal-backdrop fade show"></div>');
            $("#approval_action-loader").addClass("hidden");
            $("#approval_action").prop("disabled", false);
        } else {
            $("#root").removeClass("modal-open");
            $(".modal-backdrop").remove();
        }
    };

    useEffect(initialRender, [props.show]);

    const onProposalQuoteRejectStatusUpdate = () => {
        const { ProposalQuoteRejectStatus } = props;
        var ProposalQuoteRejectStatusResponse = Object.assign(
            {},
            ProposalQuoteRejectStatus
        );
        if (ProposalQuoteRejectStatusResponse.result === true) {
            QuoteRejectStatusDropdown(
                ProposalQuoteRejectStatusResponse.response
                    .opportunity_quote_reject_status
            );
        } else {
            switch (ProposalQuoteRejectStatusResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    toast.error(ProposalQuoteRejectStatusResponse.message);
                default:
            }
        }
    };
    useEffect(onProposalQuoteRejectStatusUpdate, [
        props.ProposalQuoteRejectStatus,
    ]);

    const onSubmit = (data) => {
        Swal.fire({
            icon: 'warning',
            text: `Are you sure, you want to reject these opportunities?`,
            showCancelButton: true,
            cancelButtonText: 'No',
            showConfirmButton: true,
            confirmButtonText: 'Yes',
        }).then(result => {
            if (result.isConfirmed) {
                props.handleSubmit(data);
            }
            props.handleClose();
        })
    }

    return (

        <div
            className="modal fade show"
            id="default"
            role="dialog"
            aria-labelledby="education-add"
            aria-hidden="true"
            style={{ display: `${props.show === true ? "block" : "none"}` }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="media-middle mt-1">
                            <h4 className="modal-title" id="myModalLabel1">
                                Process Rejection
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                props.handleClose();
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body no-scroll" style={{ overflow: "scroll" }}>
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control pl-0">
                                                        Rejection Reason&nbsp;
                                                        <span className="danger">*</span>
                                                    </label>
                                                    <Controller
                                                        name="opportunity_sub_status"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="opportunity_sub_status"
                                                                name="opportunity_sub_status"
                                                                dataSource={quoteRejectStatusDropdown}
                                                                allowFiltering={false}
                                                                placeholder="Select Rejection Reason"
                                                                value={field.value}
                                                                fields={{ text: "name", value: "name" }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.name);
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                        rules={{
                                                            required: "Reason is required",
                                                        }}
                                                    />
                                                    {errors.opportunity_sub_status && (
                                                        <ErrorLabel
                                                            message={
                                                                errors.opportunity_sub_status?.message
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.handleClose();
                                            }}
                                        > Cancel</button>

                                        <button
                                            id="approval_action"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                &nbsp;Submit
                                                <span id="approval_action-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
const mapStateToProps = (state) => {
    var ProposalQuoteRejectStatus = null;

    if (
        state.BulkRejectReducer != null &&
        Object.keys(state.BulkRejectReducer).length > 0 &&
        state.BulkRejectReducer.constructor === Object
    ) {
        switch (state.BulkRejectReducer.tag) {
            case ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_SUCCESS:
                ProposalQuoteRejectStatus = Object.assign(
                    {},
                    state.BulkRejectReducer.data
                );
                delete state.BulkRejectReducer;
                return { ProposalQuoteRejectStatus };
        }
    }

    return {
        ProposalQuoteRejectStatus
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionProposalQuoteRejectStatus }, dispatch);
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(PoolBulkAction));
